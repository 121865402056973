import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

const columns = [
  { id: 'timestamp', label: 'Timestamp (GMT)', minWidth: 170 },
  { id: 'url', label: 'Url', minWidth: 100 },
  {
    id: 'process_state',
    label: 'Process State',
    minWidth: 80
  },
  { id: 'resource', label: 'Resource', minWidth: 80 },
  { id: 'event', label: 'Event ', minWidth: 80 },
  { id: 'truck_identifier', label: 'Truck ID', minWidth: 80 },
  { id: 'guide', label: 'Guide ID', minWidth: 80 }
]

const columnsPP = [
  { id: 'timestamp', label: 'Timestamp (GMT)', minWidth: 170 },
  { id: 'url', label: 'Url', minWidth: 100 },
  {
    id: 'route_id',
    label: 'Route ID',
    minWidth: 80
  },
  { id: 'stops', label: 'Stops', minWidth: 80 },
  { id: 'vehicle_identifier', label: 'Vehicle ID', minWidth: 80 }
]

const useStyles = makeStyles({
  root: {
    verticalAlign: 'text-top'
  },
  text: {
    marginTop: 0
  }
})

export default function LogTable (props) {
  const classes = useStyles()
  const { logsData, product } = props
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  var ofColumn
  if (product === 'LM') {
    ofColumn = columns
  } else {
    ofColumn = columnsPP
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {ofColumn.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {logsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                  {ofColumn.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={logsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}
