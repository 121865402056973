import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { Button } from '..'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '10%',
    left: '31%',
    width: '38%',
    backgroundColor: '#ffffff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  title: {
    textAlign: 'center'
  },
  row: {
    padding: '25px'
  },
  alignRight: {
    float: 'right'
  }
}))

const ModalB = (props) => {
  const {
    setOpen,
    open,
    content,
    onHandleButton,
    labelButton
  } = props

  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div>
        <div className={classes.paper}>
          {content}
          <div className={classes.row}>
            <div className={classes.alignRight}>
              <Button
                onClick={() => setOpen(false)}
                label='Cancelar'
                type='primary'
                size='small'
              />
              <Button
                onClick={() => onHandleButton()}
                label={labelButton}
                type='secondary'
                size='small'
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalB
