import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import { FilterCard } from './components'
import FilterListIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles((theme) => ({
  logoTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fieldTitle: {
    fontWeight: 'bold',
    textAlign: 'left'
  },
  expand: {
    transform: 'rotate(270deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(0deg)'
    }
  },
  expandOpen: {
    transform: 'rotate(90deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(180deg)'
    }
  }
}))

const FiltersPanel = (props) => {
  const { setPanel, panel, keys } = props
  const classes = useStyles()
  return (
    <div className={classes.panel}>
      <div className={classes.logoTitle}>
        {(panel > 1) && <h4>Fields Pannel</h4>}
        {(panel <= 1) && <FilterListIcon />}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: (panel > 1)
          })}
          onClick={setPanel}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      {(panel > 1) &&
        <div className={classes.content}>
          <div className={classes.fieldTitle}>
            <p>Atirbutos seleccionados</p>
          </div>
          <div className={classes.fieldTitle}>
            <p>Atributos</p>
          </div>
          {keys.map((key, i) => {
            return (
              <FilterCard
                key={i}
                keyName={key}
              />
            )
          })}
        </div>}
    </div>
  )
}
export default FiltersPanel
