import axios from 'axios'
import { URL } from '../url'

export default async function editPassword (token, oldPassword, newPassword) {
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const body = {
    old_password: oldPassword,
    new_password: newPassword
  }
  const response = await axios.patch(URL + '/change_password', body, headers)
  return response
}
