import axios from 'axios'
import { URL } from '../url'

export default async function getCategories () {
  const headers = {
    headers: {

    }
  }
  const response = await axios.get(URL + '/categories', headers)
  return response
}
