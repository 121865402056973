import React, { useState, useEffect } from 'react'
import { Button, TextField } from '../../../../../../components'
import getUsersProfile from '../../../../../../utils/fetch/usersProfile'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import editUser from '../../../../../../utils/fetch/editUser'
import Modal from '@material-ui/core/Modal'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#1b1b1b',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#f5b63e',
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch)

const useStyles = makeStyles(theme => ({
  root: {
    margin: '3%'
  },
  paper: {
    position: 'absolute',
    top: '20%',
    left: '31%',
    width: '38%',
    backgroundColor: '#ffffff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  title: {
    textAlign: 'center'
  },
  row: {
    padding: '25px'
  },
  alignRight: {
    float: 'right'
  }
}))

const ModalEdit = (props) => {
  const auth = useSelector(state => state.auth)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [admin, setAdmin] = useState(false)
  const classes = useStyles()
  const { open, setOpen, id, getUsers } = props
  const handleEdit = async () => {
    await editUser(auth.currentToken, props.id, admin)
    setOpen(false)
    setLoading(true)
    getUsers(0, auth.currentToken)
  }
  const handleClose = () => {
    setOpen(false)
    setLoading(true)
  }
  useEffect(() => {
    async function fetchUser () {
      const result = await getUsersProfile(auth.currentToken, id)
      setData(result.data)
      setAdmin(result.data.admin)
      setLoading(false)
    }
    if (open) {
      fetchUser()
    }
  }, [open, auth.currentToken, id])

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div>
        {!loading && (
          <div className={classes.paper}>
            <div className={classes.title}>
              <h1>Editar Usuario</h1>
            </div>
            <Divider />
            <div className={classes.row}>
              <TextField
                placeholder='Email'
                label='Mail'
                value={data.email}
                disabled
              />
            </div>
            <div className={classes.row}>
              <Typography component='div'>
                <Grid component='label' container alignItems='center' spacing={1}>
                  <Grid item>Usuario general</Grid>
                  <Grid item>
                    <AntSwitch checked={admin} onChange={(e) => setAdmin(e.target.checked)} name='checkedC' />
                  </Grid>
                  <Grid item> Admin</Grid>
                </Grid>
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.alignRight}>
                <Button
                  onClick={() => setOpen(false)}
                  label='Cancelar'
                  type='primary'
                  size='small'
                />
                <Button
                  onClick={handleEdit}
                  label='Guardar'
                  type='secondary'
                  size='small'
                />
              </div>
            </div>

          </div>
        )}
        {loading && (
          <CircularProgress />
        )}
      </div>
    </Modal>
  )
}
export default ModalEdit
