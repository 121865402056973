import React from 'react'
import { Modal, TextField } from '../../../components'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  row: {
    padding: '15px'
  },
  title: {
    textAlign: 'center'
  }
}))

const ModalInput = (props) => {
  const classes = useStyles()
  const content = (
    <div>
      <h1 className={classes.title}>Crear Categoría</h1>
      <Divider />
      <div className={classes.row}>
        <TextField
          placeholder='Titulo'
          label='Titulo de la categoría'
          value={props.newname}
          onChange={(e) => props.newnamefunc(e.target.value)}
          name='newkey'
        />
      </div>
      <div className={classes.row}>
        <TextField
          placeholder='Descripción'
          label='Descripción'
          value={props.newDescription}
          onChange={(e) => props.newdescriptionfunc(e.target.value)}
          name='comment'
        />
      </div>
      <div className={classes.row}>
        <TextField
          placeholder='Ej: #1b1b1b'
          label='Elige un color HTML'
          value={props.newcolor}
          onChange={(e) => props.newcolorfunc(e.target.value)}
          name='comment'
        />
      </div>
    </div>
  )
  return (
    <Modal
      open={props.open}
      setOpen={props.openfunc}
      onHandleButton={props.handleClickFunc}
      labelButton='Crear'
      content={content}
    />
  )
}
export default ModalInput
