import React from 'react'
import { Grid } from '@material-ui/core'
import BEEVN from '../../../../assets/images/BEE VN.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  littleContainer: {
    width: '85%',
    margin: 'auto'
  },
  img: {
    maxWidth: '100%',
    height: 'auto'
  },
  subText: {
    textAlign: 'left',
    paddingLeft: '10%'
  },
  subtitle: {
    marginTop: 5,
    fontWeight: '500'
  },
  title: {
    marginTop: 0
  },
  imgCont: {
    paddingTop: '6%'
  }
}))
const BeetrackInfoContainer = (props) => {
  const { classes } = props
  const classesContainer = useStyles()
  const [t, i18n] = useTranslation("global")
  
  return (
    <div className={classes.subContainer}>
      <div className={classesContainer.littleContainer}>
        <h2>{t("beetrack-info-container.connect")}</h2>
        <Grid
          container spacing={2}
          direction='row'
          justify='space-evenly'
          className={classes.productGrid}
        >
          <Grid item xs={12} lg={3} md={12}>
            <div className={classesContainer.imgCont}>
              <img alt='landing-img' className={classesContainer.img} src={BEEVN} />
            </div>
          </Grid>
          <Grid item xs={12} lg={9} md={12}>
            <div className={classesContainer.subText}>
              <h4 className={classesContainer.title}>
                {t("beetrack-info-container.easy-app")}
              </h4>
              <h4 className={classesContainer.subtitle}> {t("beetrack-info-container.dev-portal")} </h4>
            </div>
          </Grid>
        </Grid>
        <h6> {t("beetrack-info-container.benefits")} </h6>
      </div>
    </div>
  )
}
export default BeetrackInfoContainer
