import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Button from '../../../../components/Button'
import withWidth from '@material-ui/core/withWidth'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3%',
    alignItems: 'center'
  },
  text: {
    textAlign: 'justify'
  }
}))

const ApiKeys = (props) => {
  const { handleOpenNew } = props
  const classes = useStyles()
  const { width } = props
  const [t, i18n] = useTranslation("global")

  return (
    <Container className={props.classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <div className={classes.text}>
            <h3>{t("profile.configure-api-key")}</h3>
            <p> {t("profile.api-key-info")} </p>
          </div>
        </Grid>
        <Grid item xs={9}>
          <List>

            {props.keys.map((key, i) => {
              return (
                <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar>
                      <VpnKeyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={width === 'xs' || width === 'sm' ? 'API KEY: ' + key.key_decrypted.substring(key.key_decrypted.length - 6) : 'API KEY: ' + key.key_decrypted}
                    secondary={key.comment}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge='end' aria-label='delete' onClick={() => { props.handleDelete(key.id) }}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
          <br />
          <Button label={t("profile.add")} type='secondary' size='medium' onClick={() => handleOpenNew(true)} />
        </Grid>
      </Grid>
    </Container>
  )
}
export default withWidth()(ApiKeys)
