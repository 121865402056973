import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Logs, Graphs } from './components'
import Divider from '@material-ui/core/Divider'
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: 'White',
    minHeight: '500px',
    borderRadius: 10
  },
  header: {
    padding: '1%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  selected: {
    fontFamily: '"Open Sans"',
    color: '#f5b63e',
    borderRadius: '0px',
    borderBottom: '2px solid #f5b63e'
  },
  normal: {
    fontFamily: '"Open Sans"'
  },
  tab: {
    padding: '1%'
  }
}))

const DashboardPanel = (props) => {
  const {
    selectWebhooks,
    data,
    eventData,
    trucksData,
    resourceData,
    processStateData,
    logsData,
    product
  } = props
  const classes = useStyles()
  const [tab, setTab] = useState(true)
  useEffect(() => {
  }, [selectWebhooks])
  return (
    <div className={classes.container}>
      {/* <div className={classes.header}>
        <h4>Webhooks seleccionados:  </h4>
        {selectWebhooks.map((wh, i) => {
          return <Chip key={i} label={wh} variant='outlined' />
        })}
      </div> */}
      <div className={classes.content}>
        <div className={classes.tab}>
          <Button
            className={!tab ? classes.normal : classes.selected}
            onClick={() => setTab(true)}
          >Logs
          </Button>
          <Button
            className={tab ? classes.normal : classes.selected}
            onClick={() => setTab(false)}
          >Gráficos
          </Button>
        </div>
        <Divider variant='middle' />
        <div className={classes.component}>
          {tab &&
            <Logs
              product={product}
              whs={selectWebhooks}
              data={data}
              logsData={logsData}
            />}
          {!tab &&
            <Graphs
              eventKey={1}
              product={product}
              eventData={eventData}
              trucksData={trucksData}
              resourceData={resourceData}
              processStateData={processStateData}
            />}
        </div>
      </div>
    </div>
  )
}
export default DashboardPanel
