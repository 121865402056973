import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '../../components'
import { makeStyles } from '@material-ui/styles'
import { amber } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import DOMPurify from 'dompurify'
import Divider from '@material-ui/core/Divider'
import deleteComment from '../../utils/community/DeleteComment'
import withWidth from '@material-ui/core/withWidth'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import postLikeComment from '../../utils/community/PostLikeComment'
import deleteLikeComment from '../../utils/community/DeleteLikeComment'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '3%',
    paddingTop: '1%',
    fontFamily: '"Open Sans"'
  },
  text: {
    padding: '15px'
  },
  warningText: {
    color: 'red'
  },
  amber: {
    color: 'black',
    backgroundColor: amber[600],
    width: '100px',
    height: '100px',
    margin: 'auto'
  },
  container: {
    marginTop: '30px',
    alignItems: 'left',
    flexGrow: 1
  },
  icon: {
    alignItems: 'center',
    display: 'flex'
  },
  button: {
    flexGrow: 1,
    textAlign: 'right'
  },
  editor: {
    margin: '30px'
  }
}))

const Comment = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const [t, i18n] = useTranslation("global")
  const [open, setOpen] = useState(false)
  const sanitizer = DOMPurify.sanitize
  const { width, comment } = props
  const handleClick = () => {
    setOpen(true)
  }
  const handleDelete = async () => {
    await deleteComment(auth.currentToken, comment.id)
    setOpen(false)
    props.reloadfunc(props.reload + 1)
  }

  const likeComment = async () => {
    await postLikeComment(auth.currentToken, comment.id)
    props.reloadfunc(props.reload + 1)
  }
  const unlikeComment = async () => {
    await deleteLikeComment(auth.currentToken, comment.id)
    props.reloadfunc(props.reload + 1)
  }
  return (
    <div className={classes.root}>
      <p> {t("community.answer2")} - {t("community.created-at")} {moment(comment.timestamp).format('DD/MM/YYYY')}</p>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={1}>
          {width !== 'xs' ? <Avatar> {comment.user[0].toUpperCase()}</Avatar> : null}
          <p> {width !== 'xs' ? comment.user : null}</p>
        </Grid>
        <Grid item xs={width === 'xs' ? 9 : 10}>
          <span dangerouslySetInnerHTML={{ __html: sanitizer(comment.description) }} />
        </Grid>
        <Grid item xs={width === 'xs' ? 2 : 1}>
          {!comment.liked && (
            <IconButton aria-label='like' onClick={() => likeComment()}>
              <FavoriteBorderIcon />
              <span> {comment.likes} </span>
            </IconButton>
          )}
          {comment.liked && (
            <IconButton style={{ color: 'red' }} aria-label='like' onClick={() => unlikeComment()}>
              <FavoriteIcon />
              <span> {comment.likes} </span>
            </IconButton>
          )}
          {(comment.user_id === auth.currentId || auth.currentUserRole) && (
            <IconButton aria-label='delete' onClick={handleClick}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Modal
        key='simple-modal'
        open={open}
        content={<div><h1>Eiminar comentario</h1></div>}
        setOpen={setOpen}
        onHandleButton={handleDelete}
        labelButton='Eliminar comentario'
      />
    </div>
  )
}

export default withWidth()(Comment)
