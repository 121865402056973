import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Announcement, Categories, NewCategory } from './Components'
import Grid from '@material-ui/core/Grid'
import withWidth from '@material-ui/core/withWidth'
import Button from '../../components/Button'
import postCategory from '../../utils/community/NewCategory'
import { useSelector } from 'react-redux'
import { TextField } from '../../components'
import { IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import handleLogout from '../../utils/connect/logout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '3%'
  },
  announce: {
    textAlign: 'justify'
  },
  dividerRight: {
    borderRight: '0.1px solid rgba(133, 133, 133,0.5)',
    padding: '0.5em'
  },
  dividerBottom: {
    borderBottom: '0.1px solid rgba(133, 133, 133,0.5)',
    padding: '0.5em'
  },
  dividerTop: {
    borderTop: '0.1px solid rgba(133, 133, 133,0.5)',
    padding: '0.5em'
  },
  searchBox: {
    paddingBottom: '1em'
  }
}))

const Community = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [newName, setNewName] = useState('')
  const [newColor, setNewColor] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [search, setSearch] = useState('')
  const auth = useSelector(state => state.auth)
  const { history, width } = props
  const [t, i18n] = useTranslation("global")

  const handleNewCategory = async () => {
    try {
      await postCategory(auth.currentToken, newName, newColor, newDescription)
      setNewName('')
      setNewDescription('')
      setNewColor('')
      setOpen(false)
    } catch (error) {
      if (error.response.status === 401) {
        handleLogout(history)
      }
    }
  }

  const searchElement = () => {
    history.push('/community/search/' + search.toString())
  }

  return (
    <div className={classes.root}>
      <div className={classes.searchBox}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={11}>
            <TextField
              value={search}
              placeholder={t("community.search")}
              label={t("community.search-label")}
              onChange={(e) => setSearch(e.target.value)}
              name='search'
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <IconButton onClick={searchElement}>
              <SearchIcon fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <div className={width === 'xs' ? classes.dividerBottom : classes.dividerRight}>
            <Categories history={history} />
            {auth.currentUserRole && (
              <Button label='Crear Categoría' type='secondary' size='small' onClick={() => setOpen(true)} />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <div className={classes.announce}>
            <Announcement history={history} />
          </div>
        </Grid>
      </Grid>
      <NewCategory open={open} openfunc={setOpen} newnamefunc={setNewName} newcolorfunc={setNewColor} newdescriptionfunc={setNewDescription} newname={newName} newcolor={newColor} newDescription={newDescription} handleClickFunc={handleNewCategory} />
    </div>
  )
}

export default withWidth()(Community)
