import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/styles'
import { amber } from '@material-ui/core/colors'
import postArticle from '../../utils/community/Post'
import { useParams } from 'react-router-dom'
import Button from '../../components/Button'
import TextField from '../../components/TextField'
import handleLogout from '../../utils/connect/logout'
import TextEditor from '../../components/TextEditor'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%',
    fontFamily: '"Open Sans"',
    textAlign: 'justify'
  },

  text: {
    padding: '15px'
  },
  warningText: {
    color: 'red'
  },
  amber: {
    color: 'black',
    backgroundColor: amber[600],
    width: '100px',
    height: '100px',
    margin: 'auto'
  },
  container: {
    marginTop: '30px',
    alignItems: 'left',
    flexGrow: 1
  },
  icon: {
    alignItems: 'center',
    display: 'flex'
  },
  editor: {
    margin: '30px'
  },
  buttons: {
    textAlign: 'right',
    padding: 10,
    width: '100%'
  },
  btn: {
    display: 'inline-block',
    padding: 10
  }
}))

const NewPost = (props) => {
  const auth = useSelector(state => state.auth)
  const [body, setBody] = useState('')
  const [title, setTitle] = useState('')
  const classes = useStyles()
  const { history } = props
  const { categoryId, name } = useParams()
  const [t, i18n] = useTranslation("global")

  const handleCreatePost = async () => {
    try {
      const response = await postArticle(auth.currentToken, title, body, categoryId)
      if (response.data.id) {
        history.push('/community/' + response.data.id.toString())
      }
    } catch (error) {
      if (error.response.status === 401) {
        handleLogout(history)
      }
    }
  }

  return (
    <div className={classes.root}>
      {auth.loggingIn && (
        <Container className={classes.container}>
          <h1> {t("community.new-post")} {name}</h1>
          <div className={classes.editor}>
            <TextField
              placeholder={t("community.title")}
              label={t("community.title-info")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              name='title'
            />
          </div>
          <div className={classes.editor}>
            <TextEditor html={body} setHtml={setBody} />
          </div>
          <div className={classes.buttons}>
            <div className={classes.btn}>
              <Button label={t("community.back")} size='medium' type='primary' onClick={() => history.push('/community/categories/' + name + '/' + categoryId)} />
            </div>
            <div className={classes.btn}>
              {title !== '' && body !== '<b>Aqui el cuerpo de tu publicacion<b>' &&
                body !== '' ? <Button label={t("community.create-post")} size='medium' type='secondary' onClick={handleCreatePost} />
                : <Button label={t("community.create-post")} size='medium' type='disabled' onClick={handleCreatePost} />}
            </div>
          </div>
        </Container>
      )}
    </div>
  )
}

export default NewPost
