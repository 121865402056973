import axios from 'axios'
import { URL } from '../url'

export default async function createKey (token, key, comment, type) {
  const body = {
    key_encrypted: key,
    comment: comment,
    product: type
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  console.log(body)
  const response = await axios.post(URL + '/apikeys', body, headers)
  return response
}
