import React from 'react'
import Modal from '@material-ui/core/Modal'
import { Button } from '../../../../../../components'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '20%',
    left: '31%',
    width: '38%',
    backgroundColor: '#ffffff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  title: {
    textAlign: 'center'
  },
  row: {
    padding: '25px'
  },
  alignRight: {
    float: 'right'
  }
}))

const Confirmation = (props) => {
  const { open, setOpen, handleDelete, email, id } = props
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div>
        <div className={classes.paper}>
          <div className={classes.title}>
            <h1>Eliminar </h1>
            <h1>{email}</h1>
          </div>
          <Divider />
          <div className={classes.row}>
            Esta acción tiene consecuencias irreparables.
          </div>
          <div className={classes.row}>
            <div className={classes.alignRight}>
              <Button
                onClick={() => setOpen(false)}
                label='Cancelar'
                type='primary'
                size='small'
              />
              <Button
                onClick={() => handleDelete(id)}
                label='Eliminar'
                type='secondary'
                size='small'
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Confirmation
