import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      color: theme.palette.text.secondary
    },
    '&:focus > $content': {
      color: '#f5b63e'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent !important'
    }
  },
  content: {
    color: '#1b1b1b',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  menuItem: {
    fontSize: 20,
    padding: '2%',
    fontFamily: 'Open Sans',
    fontWeight: 'bold'
  },
  childItem: {
    padding: '1%',
    fontSize: 18,
    fontFamily: 'Open Sans'
  }
}))

export default function DocTreeView (props) {
  const classes = useStyles()
  const { useCase, cases, history } = props
  const [expanded, setExpanded] = useState([])
  const [selected, setSelected] = useState([])

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event, nodeIds) => {
    history.push('/cases/' + nodeIds.toString())
    setSelected(nodeIds)
  }
  useEffect(() => {
    function getOpen (caseId) {
      cases.forEach((first) => {
        if (first.id.toString() === caseId) {
          setExpanded([caseId.toString()])
          setSelected([caseId.toString()])
        } else {
          first.childs.forEach((second) => {
            if (second.id.toString() === caseId) {
              setExpanded([first.id.toString(), caseId.toString()])
              setSelected([first.id.toString(), caseId.toString()])
            } else {
              second.childs.forEach((third) => {
                if (third.id.toString === caseId) {
                  setExpanded([first.id.toString(), second.id.toString(), caseId.toString()])
                  setSelected([first.id.toString(), second.id.toString(), caseId.toString()])
                }
              })
            }
          })
        }
      })
    }
    getOpen(useCase)
  }, [useCase, cases])

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {cases.map((doc) =>
        <TreeItem
          key={doc.id}
          nodeId={doc.id}
          label={<div className={classes.menuItem}> {doc.title} </div>}
          style={{
            '--tree-view-color': '#1a73e8',
            '--tree-view-bg-color': '#e8f0fe'
          }}
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label
          }}
        >
          {doc.childs.map((subdoc) =>
            <TreeItem key={subdoc.id} nodeId={subdoc.id} label={<div className={classes.childItem}> {subdoc.title} </div>}>
              {subdoc.childs.map((subsubdoc) =>
                <TreeItem key={subsubdoc.id} nodeId={subsubdoc.id} label={subsubdoc.title} />
              )}
            </TreeItem>
          )}
        </TreeItem>
      )}
    </TreeView>
  )
}
