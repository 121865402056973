import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ForumIcon from '@material-ui/icons/Forum'
import DOMPurify from 'dompurify'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import FavoriteIcon from '@material-ui/icons/Favorite'
import Pagination from '@material-ui/lab/Pagination'
import { TextField, CircularProgress } from '../../components'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import searchArticles from '../../utils/community/SearchArticles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%'
  },
  text: {
    textAlign: 'justify'
  },
  btn: {
    textAlign: 'right'
  }
}))

const SearchAll = (props) => {
  const classes = useStyles()
  const { query } = useParams()
  const { history } = props
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [maxPages, setMaxPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const sanitizer = DOMPurify.sanitize

  const viewArticle = (articleId) => {
    history.push('/community/' + articleId.toString())
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const searchElement = () => {
    history.push('/community/search/' + search.toString())
  }

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const result = await searchArticles(query, currentPage)
      setMaxPages(result.data.pages)
      setArticles(result.data.data)
      setLoading(false)
      // ...
    }
    fetchData()
  }, [currentPage, query])
  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          <div className={classes.text}>
            <h1> Resultados de '{query}' </h1>
          </div>
          <div className={classes.searchBox}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={11}>
                <TextField
                  value={search}
                  placeholder='Buscar'
                  label='Buscar en toda la comunidad'
                  onChange={(e) => setSearch(e.target.value)}
                  name='search'
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={searchElement}>
                  <SearchIcon fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <List className={classes.root}>
            {articles.length === 0 ? <h3> Sin Resultados </h3> : null}
            {articles.map((article, k) =>
              <ListItem button key={k} onClick={() => viewArticle(article.id)}>
                <ListItemAvatar>
                  <ForumIcon />
                </ListItemAvatar>
                <ListItemText primary={<div> <span>{moment(article.created_at).format('DD/MM/YYYY')}</span> <h4>{article.title}</h4> </div>} secondary={<span dangerouslySetInnerHTML={{ __html: sanitizer(article.description) }} />} />
                <Grid container alignItems='flex-start' justify='flex-end' direction='row'>
                  <FavoriteIcon />
                  <span> {article.likes} </span>
                </Grid>
              </ListItem>)}
          </List>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Pagination count={maxPages} variant='outlined' shape='rounded' page={currentPage} onChange={handlePageChange} />
            </Grid>
          </Grid>
        </div>
      )}
      {loading && (
        <CircularProgress />
      )}
    </div>
  )
}

export default SearchAll
