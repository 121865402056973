import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import getCases from '../../../../utils/cases/Cases'
import getStats from '../../../../utils/cases/Stats'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    color: '#3b4754',
    paddingLeft: '2%',
    '& h1': {
      fontSize: '2.3em',
      fontWeight: '800'
    },
    '& h3': {
      fontSize: '1.3em',
      fontWeight: '500'
    },
    '& h4': {
      fontSize: '1.0em'
    }
  }
}))

const Confluence = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const [fatherPages, setFatherPages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const cases = await getCases()
      // setFatherPages(cases.data)
      const stats = await getStats(auth.currentToken)
      cases.data.forEach(father => {
        father.likes = 'sin data'
        father.dislikes = 'sin data'
        father.aproval = 'sin data'
        stats.data.forEach(stat => {
          if (stat.id_page.toString() === father.id) {
            father.likes = stat.likes
            father.dislikes = stat.dislikes
            father.aproval = (stat.likes) / (stat.likes + stat.dislikes) * 100
          }
        })
        father.childs.forEach(child => {
          child.likes = 'sin data'
          child.dislikes = 'sin data'
          child.aproval = 'sin data'
          stats.data.forEach(stat => {
            if (stat.id_page.toString() === child.id) {
              child.likes = stat.likes
              child.dislikes = stat.dislikes
              child.aproval = (stat.likes) / (stat.likes + stat.dislikes) * 100
            }
          })
          child.childs.forEach(subChild => {
            subChild.likes = 'sin data'
            subChild.dislikes = 'sin data'
            subChild.aproval = 'sin data'
            stats.data.forEach(stat => {
              if (stat.id_page.toString() === subChild.id) {
                subChild.likes = stat.likes
                subChild.dislikes = stat.dislikes
                subChild.aproval = (stat.likes) / (stat.likes + stat.dislikes) * 100
              }
            })
          })
        })
      })
      setFatherPages(cases.data)
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h1>Estadísticas Páginas Confluence</h1>
        {!loading && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell> <strong> ID </strong></TableCell>
                  <TableCell><strong> Nombre Página </strong></TableCell>
                  <TableCell align='right'><strong> Likes </strong></TableCell>
                  <TableCell align='right'><strong> Dislikes </strong></TableCell>
                  <TableCell align='right'><strong> Aprobación </strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fatherPages.map((fatherPage) => (
                  <>
                    <TableRow key={fatherPage.id}>
                      <TableCell component='th' scope='row'>
                        {fatherPage.id}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <a href={'/cases/' + fatherPage.id.toString()}>{fatherPage.title} </a>
                      </TableCell>
                      <TableCell align='right'>{fatherPage.likes}</TableCell>
                      <TableCell align='right'>{fatherPage.dislikes}</TableCell>
                      <TableCell align='right'>{fatherPage.aproval} %</TableCell>
                    </TableRow>
                    {fatherPage.childs.map((child) => (
                      <>
                        <TableRow key={child.id}>
                          <TableCell component='th' scope='row'>
                            {child.id}
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            <a href={'/cases/' + child.id.toString()}>{child.title} </a>
                          </TableCell>
                          <TableCell align='right'>{child.likes}</TableCell>
                          <TableCell align='right'>{child.dislikes}</TableCell>
                          <TableCell align='right'>{child.aproval} %</TableCell>
                        </TableRow>
                        {child.childs.map((subChild) => (
                          <TableRow key={subChild.id}>
                            <TableCell component='th' scope='row'>
                              {subChild.id}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              <a href={'/cases/' + subChild.id.toString()}>{subChild.title} </a>
                            </TableCell>
                            <TableCell align='right'>{subChild.likes}</TableCell>
                            <TableCell align='right'>{subChild.dislikes}</TableCell>
                            <TableCell align='right'>{subChild.aproval} %</TableCell>
                          </TableRow>
                        ))}
                      </>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {loading && (
          <CircularProgress />
        )}
      </div>
    </div>
  )
}
export default Confluence
