import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      color: theme.palette.text.secondary
    },
    '&:focus > $content, &$selected > $content': {
      color: '#f5b63e'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent !important'
    }
  },
  content: {
    color: '#1b1b1b',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  menuItem: {
    fontSize: 25,
    fontFamily: 'Open Sans',
    fontWeight: 'bold'
  },
  childItem: {
    fontSize: 20,
    fontFamily: 'Open Sans'
  }
}))

function StyledTreeItem (props) {
  const classes = useTreeItemStyles()
  const { labelText, labelInfo, color, bgColor, parent, ...other } = props

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <div className={parent ? classes.menuItem : classes.childItem}>
            {labelText}
          </div>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  )
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400
  }
})

export default function BeetrackTreeView (props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(['0'])
  const [exp, setExp] = useState(0)
  const { app1, apps, setApp } = props
  const language = navigator.languages[0].split("-")[0] === "es" ? "es" : "en"
  const expand = (nodeId, app) => {
    setExpanded([nodeId])
    setApp(app)
  }
  useEffect(() => {
    if (app1 && exp === 0) {
      setExpanded([(app1.id - 1).toString()])
      setExp(1)
    }
  }, [app1, exp])
  return (
    <div>
      <link href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap' rel='stylesheet' />
      <TreeView
        className={classes.root}
        defaultExpanded={['0']}
        expanded={expanded}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {apps.map((app, k) => {
          return (
            <StyledTreeItem nodeId={k.toString()} labelText={app.name} parent onClick={() => expand(k.toString(), app)} key={k}>
              {app.services.map((service, i) => {
                return (
                  <StyledTreeItem
                    onClick={() => setApp(service)}
                    nodeId={k.toString() + i.toString()}
                    labelText={language === "es" ? service.name : service.name_en}
                    color='#1a73e8'
                    bgColor='#e8f0fe'
                    variant='body1'
                    key={i}
                  />
                )
              })}
            </StyledTreeItem>
          )
        })}
      </TreeView>
    </div>
  )
}
