import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Graph } from './components'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  chartsConatiner: {
    display: 'flex',
    margin: 'auto'
  },
  barChart: {
    width: '100%',
    margin: 'auto'
  },
  bb: {
    borderBottom: '1px solid #f5b63e'
  },
  br: {
    borderRight: '1px solid #f5b63e'
  },
  bt: {
    borderTop: '1px solid #f5b63e'
  }
})

export default function Graphs (props) {
  const classes = useStyles()
  const {
    graphLogKey,
    eventData,
    processStateData,
    resourceData,
    trucksData,
    product
  } = props

  return (
    <div>
      <link rel='stylesheet' href='https://unpkg.com/react-vis/dist/style.css' />
      <Grid container spacing={0} className={classes.chartsConatiner}>
        <Grid item xs={12} sm={6}>
          <div className={`${classes.barChart}`}>
            <Graph
              key={graphLogKey}
              data={eventData}
              title={product === 'LM' ? 'Tipos de eventos de webhooks' : 'Vehículos que registraron webhooks'}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={`${classes.barChart}`}>
            <Graph
              key={graphLogKey}
              data={processStateData}
              title={product === 'LM' ? 'Estado del proceso de webhooks' : 'Cantidad de stops realizados por hit'}
            />
          </div>
        </Grid>
      </Grid>
      {product === 'LM' &&
        <Grid container spacing={0} className={classes.chartsConatiner}>
          <Grid item xs={12} sm={6}>
            <div className={`${classes.barChart} `}>
              <Graph
                key={graphLogKey}
                data={resourceData}
                title='Recursos utilizados en webhooks'
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.barChart}>
              <Graph
                key={graphLogKey}
                data={trucksData}
                title='Usuarios móvil que registraron webhooks'
              />
            </div>
          </Grid>
        </Grid>}
    </div>
  )
}
