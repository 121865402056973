import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'
import BEE from '../../assets/images/BEE.png'
import { useTranslation } from 'react-i18next'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  padding: {
    backgroundColor: '#ffffff',
    paddingTop: '50px'
  },
  footer: {
    marginTop: '0px',
    height: '100%',
    backgroundColor: '#2d2926',
    opacity: '99.9%',
    color: '#f8f8f8 !important',
    fontSize: '0.875rem',
    fontFamily: '"Lato",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important',
    '& h2': {
      fontSize: '1.75rem',
      color: '#f8f8f8 !important',
      fontWeight: 'bold !important'
    },
    '& h3': {
      fontSize: '0.9rem !important',
      fontWeight: 'bold !important',
      color: '#f5b63e',
      margin: '18px 0px !important'
    },
    '& a': {
      textDecoration: 'none',
      color: '#f8f8f8 !important'
    },
    '& a:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    '& p': {
      margin: '14px 0px !important'
    },
    '& p:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  container: {
    maxWidth: '80%',
    margin: 'auto',
    textAlign: 'left',
    paddingTop: '5%'
  },
  text: {
    paddingTop: '1px',
    fontSize: '0.875rem'
  },
  icon: {
    margin: '14px 22px',
    [theme.breakpoints.down('md')]: {
      margin: '25.6px'
    },
    textDecoration: 'none',
    fill: '#f8f8f8'
  },
  titleDiv: {
    display: 'flex',
    paddingLeft: 10,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10
    }
  },
  productLogo: {
    margin: '27px 10px',
    height: '35px'
  },
  firstGrid: {
    borderColor: '#707070',
    borderWidth: '2px',
    marginTop: '40px'
  },
  grid5: {
    padding: '30px',
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridAutoRows: 'minmax(100px, auto)'
    },
    [theme.breakpoints.down('md')]: {
      gridAutoRows: 'minmax(200px, auto)',
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    gridGap: '10px'
  },
  itemGrid5: {
    width: '65%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    margin: 'auto',
    fontWeigh: '700',
    textAlign: 'center',
    '& p': {
      marginTop: '2px'
    },
    '& p:hover': {
      cursor: 'auto',
      textDecoration: 'none'
    }
  },
  flag: {
    height: '20px',
    margin: '15px 10px 15px 0px'
  },
  flagDiv: {
    '& p': {
      marginTop: '15px',
      textAlign: 'center'
    },
    display: 'flex',
    justifyContent: 'center'
  }
}))

function Footer (props) {
  const classes = useStyles()
  const history = useHistory()
  const [t, i18n] = useTranslation("global")
  return (
    <div className={classes.padding}>
      <div className={classes.footer}>
        <div className={classes.container}>
          <div className={classes.titleDiv}>
            <img alt='landing-img' className={classes.productLogo} src={BEE} />
            <h2>
              {t("footer.moto")}
            </h2>
          </div>
          <Grid
            container
            direction='row'
            justify='space-evenly'
          >
            <Grid item xs={12} lg={4} md={6}>
              <div className={classes.item}>
                <div className={classes.icons}>
                  <a href='https://www.facebook.com/beetrackcl'>
                    <FacebookIcon className={classes.icon} />
                  </a>
                  <a href='https://www.instagram.com/beetrack_com'>
                    <InstagramIcon className={classes.icon} />
                  </a>
                  <a href='https://www.twitter.com/beetrack'>
                    <TwitterIcon className={classes.icon} />
                  </a>
                  <a href='https://www.linkedin.com/company/beetrack'>
                    <LinkedInIcon className={classes.icon} />
                  </a>
                  <a href='https://www.youtube.com/c/Beetrack'>
                    <YouTubeIcon className={classes.icon} />
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} lg={2} md={6}>
              <div className={classes.item}>
                <h3>{t("footer.explore")}</h3>
                <div className={classes.text}>
                  <p onClick={() => history.push('/services')}>{t("footer.products")}</p>
                  <p onClick={() => history.push('/cases')}>{t("footer.user-manual")}</p>
                  <p onClick={() => history.push('/docs')}>{t("footer.documentation")}</p>
                  <p onClick={() => history.push('/community')}>{t("footer.community")}</p>
                  <p onClick={() => history.push('/faq')}>FAQ</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} lg={2} md={6}>
              <div className={classes.item}>
                <h3>{t("footer.products")}</h3>
                <div className={classes.text}>
                  <p onClick={() => history.push('/services/lastmile')}>LastMile</p>
                  <p onClick={() => history.push('/services/plannerpro')}>PlannerPro</p>
                  {/* <p onClick={() => history.push('/services/ondemand')}>On Demand</p> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} lg={2} md={6}>
              <div className={classes.item}>
                <h3>{t("footer.developers")}</h3>
                <div className={classes.text}>
                  <p onClick={() => history.push('/sign-in')}>{t("footer.starting")}</p>
                  <p onClick={() => history.push('/docs')}>{t("footer.documentation")}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} lg={2} md={6}>
              <div className={classes.item}>
                <h3>Beetrack</h3>
                <div className={classes.text}>
                  <p><a href='https://www.beetrack.com/es/nosotros'>{t("footer.us")}</a></p>
                  {/* <p>Términos y condiciones</p> */}
                  <p><a href='https://www.beetrack.com'>{t("footer.link")}</a></p>
                </div>
              </div>
            </Grid>
          </Grid>
          <hr className={classes.firstGrid} />
          <div className={classes.grid5}>
            <div className={classes.itemGrid5}>
              <div className={classes.flagDiv}>
                <img alt='landing-img' className={classes.flag} src='https://www.beetrack.com/hs-fs/hubfs/Beetrack2020/footer/chile.png?width=140&height=96&name=chile.png' />
                <p>Chile</p>
              </div>
              <a href='tel: +56 2 2938 1295'>+56 2 2938 1295</a>
            </div>
            <div className={classes.itemGrid5}>
              <div className={classes.flagDiv}>
                <img alt='landing-img' className={classes.flag} src='https://www.beetrack.com/hs-fs/hubfs/Beetrack2020/footer/mexico.png?width=140&height=96&name=mexico.png' />
                <p>México</p>
              </div>
              <a href='tel: +52 558 526 6063'>+52 558 526 6063</a>
            </div>
            <div className={classes.itemGrid5}>
              <div className={classes.flagDiv}>
                <img alt='landing-img' className={classes.flag} src='https://www.beetrack.com/hs-fs/hubfs/Beetrack2020/footer/colombia.png?width=140&height=96&name=colombia.png' />
                <p>Colombia</p>
              </div>
              <a href='tel: +57 1 580 0253'>+57 1 580 0253</a>
            </div>
            <div className={classes.itemGrid5}>
              <div className={classes.flagDiv}>
                <img alt='landing-img' className={classes.flag} src='https://www.beetrack.com/hs-fs/hubfs/Beetrack2020/footer/peru.png?width=140&height=96&name=peru.png' />
                <p>Perú</p>
              </div>
              <a href='tel: +51 1 730 6553'>+51 1 730 6553</a>
            </div>
            <div className={classes.itemGrid5}>
              <div className={classes.flagDiv}>
                <img alt='landing-img' className={classes.flag} src='https://www.beetrack.com/hs-fs/hubfs/Beetrack2020/footer/usa.png?width=140&height=96&name=usa.png' />
                <p>USA</p>
              </div>
              <a href='tel: +1 415 523 9006'>+1 415 523 9006</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
