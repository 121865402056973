import axios from 'axios'
import { URL } from '../url'

export default async function getLogs (token, timeF, timeI, url, product, accountId) {
  const body = {
    url: url,
    time_f: timeF.toString(),
    time_i: timeI.toString(),
    product: product,
    account_id: accountId
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.post(URL + '/dashboard/webhook/url/stats', body, headers)
  return response
}
