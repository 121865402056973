import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { ProductCard } from './components'
import getApps from '../../utils/services/apps'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '4%',
    alignItems: 'center',
    width: '90%',
    margin: 'auto'
  },
  card: {
    margin: 'auto',
    maxWidth: '500px',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  icon: {
    padding: '20px'
  },
  text: {
    padding: '15px'
  },
  warningText: {
    color: 'red'
  },
  iconList: {
    justifyContent: 'center'
  },
  button: {
    maxWidth: '50%',
    marginTop: '5%',
    margin: 'auto',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  }
}))

const initials = {
  LastMile: 'LM',
  PlannerPro: 'PP'
}

const Docs = (props) => {
  const { history } = props
  const classes = useStyles()
  const [apps, setApps] = useState([])

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const result = await getApps()
      const data = result.data
      data.sort(function (a, b) {
        return (-(b.id - a.id || a.name.localeCompare(b.name)))
      })
      setApps(data)

      // ...
    }
    fetchData()
  }, [])

  const onClickDocs = (name) => {
    return history.push('/dashboard/' + name)
  }
  return (
    <div className={classes.root}>
      <Grid
        container spacing={3}
        direction='row'
        justify='space-evenly'
      >
        {apps.map((app, k) => {
          if (app.id < 3) {
            return (
              <Grid key={k} item xs={12} md={6}>
                <ProductCard name={app.id} type={initials[app.name]} onClickDocs={onClickDocs} />
              </Grid>
            )
          } else {
            return <p key={k} />
          }
        }
        )}
      </Grid>
    </div>
  )
}

export default Docs
