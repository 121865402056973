import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import BeetrackTreeView from './TreeView'
import { useSelector } from 'react-redux'
import withWidth from '@material-ui/core/withWidth'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Button from '../../../../components/Button'

const drawerWidth = 245

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    textAlign: 'right',
    flexShrink: 0
  },
  drawerPaper: {
    marginLeft: '2%',
    marginTop: '5%',
    width: drawerWidth,
    height: 'auto'
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  btn: {
    marginTop: '5%',
    textAlign: 'left',
    flexGrow: 1
  },
  fixBottom: {
    position: 'fixed',
    width: '100%',
    bottom: '0px',
    right: '0px'
  }
}))

function ClippedDrawer(props) {
  const classes = useStyles()
  const { app1, apps, setApp, setOpenNew, width } = props
  const auth = useSelector(state => state.auth)
  const [side, setSide] = useState(false)
  const handleClose = () => {
    setSide(!side)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant={width === 'lg' || width === 'md' || width === 'xl' ? 'permanent' : 'temporary'}
        open={side}
        onClose={handleClose}
        classes={{
          paper: width === 'lg' || width === 'md' || width === 'xl' ? classes.drawerPaper : null
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <BeetrackTreeView app1={app1} apps={apps} setApp={setApp} />
        </div>
        {auth.currentUserRole && (
          <div className={classes.btn}>
            <Button label='Nuevo Servicio' type='secondary' size='small' onClick={() => setOpenNew(true)} />
          </div>
        )}
      </Drawer>
      {width !== 'lg' && width !== 'md' && width !== 'xl' && (
        <Button variant='contained' aria-label='side' className={classes.fixBottom} onClick={() => setSide(true)} startIcon={<KeyboardArrowRightIcon />}> </Button>
      )}
    </div>
  )
}

export default withWidth()(ClippedDrawer)
