import axios from 'axios'
import { URL } from '../url'

export default async function recoverPassword (token, password) {
  const data = {
    token: token,
    new_password: password
  }

  const response = await axios.post(URL + '/reset_password', data)
  return response
}
