import React from 'react'
import ButtonMUI from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const bigFont = '1.3em'
const smallFont = '0.8em'
const mediumFont = '1em'
const colorHover = '#3b4754'
const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    fontFamily: '"Open Sans"',
    textTransform: 'none',
    fontWeight: 'bold',
    borderRadius: '35px',
    '&:hover': {
      backgroundColor: colorHover,
      borderColor: '#3b4754',
      color: '#fffff'
    }
  },
  small: {
    padding: '4px',
    width: '140px',
    fontSize: smallFont
  },
  medium: {
    padding: '6px',
    width: '200px',
    fontSize: mediumFont
  },
  product: {
    padding: '6px',
    width: '100px',
    fontSize: '1em'
  },
  big: {
    padding: '6px',
    width: '49%',
    fontSize: bigFont,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '4%'
    }
  },
  signin: {
    width: '100%',
    fontSize: bigFont
  },
  primary: {
    backgroundColor: '#1b1b1b'
  },
  secondary: {
    backgroundColor: '#f5b63e'
  },
  PP: {
    backgroundColor: '#B3DA05'
  },
  OD: {
    backgroundColor: '#0191B6'
  },
  LM: {
    backgroundColor: '#FB8501'
  },
  FM: {
    backgroundColor: '#FF3E50'
  },
  google: {
    backgroundColor: '#4285F4'
  },
  facebook: {
    backgroundColor: '#4267b2'
  },
  disabled: {
    backgroundColor: 'grey'
  }
}))

const Button = (props) => {
  const {
    label,
    onClick,
    type,
    size,
    component,
    disabled
  } = props
  const classes = useStyles()
  if (disabled) {
    return (<ButtonMUI disabled onClick={onClick} type='submit' component={component} className={`${classes.button} ${classes[size]} ${classes[type]}`}>{label}</ButtonMUI>)
  } else {
    return (<ButtonMUI onClick={onClick} type='submit' component={component} className={`${classes.button} ${classes[size]} ${classes[type]}`}>{label}</ButtonMUI>)
  }
}

export default Button
