import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, CircularProgress } from '../../components'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import getArticle from '../../utils/community/Article'
import getComments from '../../utils/community/Comment'
import { useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import Comment from './Comment'
import postComment from '../../utils/community/NewComment'
import deleteArticle from '../../utils/community/DeletePost'
import { Divider } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import withWidth from '@material-ui/core/withWidth'
import Button from '../../components/Button'
import TextField from '../../components/TextField'
import editArticle from '../../utils/community/EditPost'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import IconButton from '@material-ui/core/IconButton'
import postLikeArticle from '../../utils/community/PostLikeArticle'
import deleteLikeArticle from '../../utils/community/DeleteLikeArticle'
import TextEditor from '../../components/TextEditor'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%',
    textAlign: 'justify',
    fontFamily: '"Open Sans"'
  },
  editor: {
    margin: '5px'
  },
  editBtn: {
    padding: '2%'
  },
  grid: {
    paddingTop: '2%',
    paddingLeft: '1%'
  },
  btnGrpLg: {
    textAlign: 'right'
  },
  buttonLine: {
    paddingTop: '20px'
  }
}))

const Post = (props) => {
  const auth = useSelector(state => state.auth)
  const { id } = useParams()
  const [article, setArticle] = useState({})
  const [commentBody, setCommentBody] = useState('')
  const [loading, setLoading] = useState(true)
  const [comments, setComments] = useState([])
  const [reload, setReload] = useState(0)
  const [open, setOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const sanitizer = DOMPurify.sanitize
  const classes = useStyles()
  const { history, width } = props
  const [t, i18n] = useTranslation("global")

  const handleCreateComment = async () => {
    await postComment(auth.currentToken, commentBody, id)
    setReload(reload + 1)
    setCommentBody('')
  }

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleDeleteFirst = () => {
    setOpen(true)
  }

  const handleConfirmEdit = async () => {
    await editArticle(auth.currentToken, title, description, id)
    setEditMode(false)
    setReload(reload + 1)
  }
  const handleCancel = () => {
    setTitle(article.title)
    setEditMode(false)
  }
  const handleDeleteSecond = async () => {
    await deleteArticle(auth.currentToken, id)
    history.push('/community')
  }

  const likeArticle = async () => {
    await postLikeArticle(auth.currentToken, id)
    setReload(reload + 1)
  }

  const unlikeArticle = async () => {
    await deleteLikeArticle(auth.currentToken, id)
    setReload(reload + 1)
  }

  useEffect(() => {
    async function fetchData () {
      // You can await here
      await getArticle(auth.currentToken, id)
        .then(async (resultArticle) => {
          const resultComments = await getComments(auth.currentToken, id)
          resultComments.data.sort(function (a, b) {
            if (a.likes === b.likes) {
              return b.created_at - a.created_at
            }
            return b.likes > a.likes ? 1 : -1
          })
          setArticle(resultArticle.data)
          setTitle(resultArticle.data.title)
          setDescription(resultArticle.data.description)
          setComments(resultComments.data)
          setLoading(false)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            history.push('/404')
          } else {
            history.push('/community')
          }
        })
    }
    fetchData()
  }, [reload, id, auth.currentToken])

  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={width === 'xs' ? 10 : 9}>
              {!editMode && (
                <h1> {article.title} </h1>
              )}
              {editMode && (
                <TextField
                  value={title}
                  placeholder={t("community.title")}
                  label={t("community.title-label")}
                  onChange={(e) => setTitle(e.target.value)}
                  name='title'
                />
              )}
            </Grid>
            <Grid item xs={width === 'xs' ? 4 : 3}>
              <div className={classes.buttonLine}>
                <div className={classes.editBtn}>
                  <Button onClick={() => history.push('/community')} label={t("community.back")} type='secondary' size='small' />
                </div>
              </div>
              {!editMode && (article.user_id === auth.currentId || auth.currentUserRole) && (
                <div>
                  <div className={classes.editBtn}>
                    <Button onClick={handleEdit} label={t("community.edit")} type='secondary' size='small' />
                  </div>
                  <div className={classes.editBtn}>
                    <Button onClick={handleDeleteFirst} label={t("community.delete")} type='primary' size='small' />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <p> {<a href={'/community/categories/' + article.category_name + '/' + article.category_id}> {article.category_name} </a>} - {t("community.created-at")} {moment(article.created_at).format('DD/MM/YYYY')} {width === 'xs' ? ' por ' + article.owner.split('@')[0] : null}</p>
          <Divider />
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={1}>
              {width !== 'xs' ? <Avatar> {article.owner[0].toUpperCase()}</Avatar> : null}
              <p> {width !== 'xs' ? article.owner.split('@')[0] : null}</p>
            </Grid>
            <Grid item xs={11}>
              {!editMode && (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: sanitizer(article.description) }} />
                  <Grid container alignItems='flex-start' justify='flex-end' direction='row'>
                    {!article.liked && (
                      <IconButton aria-label='like' onClick={() => likeArticle()}>
                        <FavoriteBorderIcon />
                        <span> {article.likes} </span>
                      </IconButton>
                    )}
                    {article.liked && (
                      <IconButton style={{ color: 'red' }} aria-label='like' onClick={() => unlikeArticle()}>
                        <FavoriteIcon />
                        <span> {article.likes} </span>
                      </IconButton>
                    )}
                  </Grid>
                </div>
              )}
              {editMode && (
                <div className={classes.editor}>
                  <TextEditor html={description} setHtml={setDescription} />
                </div>
              )}
            </Grid>
          </Grid>
          <Divider />
          {editMode && (
            <Grid alignItems='flex-start' justify='flex-end' direction='row' container spacing={1} className={classes.grid}>
              <Grid item variant='contained'>
                <Button onClick={handleCancel} label={t("community.cancel")} type='primary' size='medium' />
              </Grid>
              <Grid item variant='contained'>
                <Button onClick={handleConfirmEdit} label={t("community.save")} type='secondary' size='medium' />
              </Grid>
            </Grid>
          )}
          {!editMode && (
            <div>
              {comments.map((comment, id) => <Comment key={id} comment={comment} reloadfunc={setReload} reload={reload} />)}
              <div className={classes.editor}>
                {auth.loggingIn && (
                  <div>
                    <p> <strong> {t("community.answer")} </strong> </p>
                    <TextEditor html={commentBody} setHtml={setCommentBody}/>
                    <div className={classes.editBtn}>
                      <Button label={t("community.add-comment")} type='secondary' size='medium' onClick={handleCreateComment} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {
        loading && (
          <CircularProgress />
        )
      }
      <Modal
        key='simple-modal'
        open={open}
        setOpen={setOpen}
        content={<div><h1>{t("community.delete-post")}</h1></div>}
        onHandleButton={handleDeleteSecond}
        labelButton={t("community.delete")}
      />
    </div>
  )
}

export default withWidth()(Post)
