import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { useSelector } from 'react-redux'
import { Button, TextField, Modal } from '../../../../components'
import editService from '../../../../utils/services/editService'
import deleteService from '../../../../utils/services/deleteService'
import { Divider } from '@material-ui/core'
import DOMPurify from 'dompurify'
import TextEditor from '../../../../components/TextEditor'

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'justify'
  },
  container: {
    textAlign: 'justify',
    float: 'left',
    flexGrow: 1,
    width: '100%',
    paddingLeft: '2%',
    paddingTop: '2%'
  },
  textField: {
    minWidth: '100%',
    padding: 100
  },
  btn: {
    marginTop: '3%',
    marginRight: '2%'
  },
  right: {
    textAlign: 'right',
    marginRight: '0'
  },
  title: {
    paddingLeft: '2%'
  },
  body: {
    padding: '1%'
  },
  editor: {
    margin: '30px'
  }
}))

const InfoCard = (props) => {
  const { app, setReload, reload, setApp } = props
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [open, setOpen] = useState(false)
  const [newName, setNewName] = useState(app ? app.name : '')
  const [newNameEn, setNewNameEn] = useState(app ? app.name_en : '')
  const [newDescription, setNewDescription] = useState(app ? app.description ? app.description : app.field ? app.field : '' : '')
  const [newDescriptionEn, setNewDescriptionEn] = useState(app ? app.description_en ? app.description_en : app.field_en ? app.field_en : '' : '')
  const [name, setName] = useState(app ? app.name : '')
  const [nameEn, setNameEn] = useState(app ? app.name_en : '')
  const [descriptionEn, setDescriptionEn] = useState(app ? app.description_en ? app.description_en : app.field_en ? app.field_en : '' : '')
  const [description, setDescription] = useState(app ? app.description ? app.description : app.field ? app.field : '' : '')
  const auth = useSelector(state => state.auth)
  const language = navigator.languages[0].split("-")[0] === "es" ? "es" : "en"
  const sanitizer = DOMPurify.sanitize

  const handleDelete = async () => {
    await deleteService(auth.currentToken, app.id)
    setReload(reload + 1)
    setReload(0)
    setOpen(false)
  }
  const handleEdit = async () => {
    const response = await editService(auth.currentToken, app.id, newName, newNameEn, newDescription, newDescriptionEn)
    setName(response.data.name)
    setNameEn(response.data.name)
    setDescription(response.data.field)
    setDescriptionEn(response.data.field_en)
    setNewName(response.data.name)
    setNewDescription(response.data.field)
    setNewDescriptionEn(response.data.field_en)
    setEditMode(false)
    setReload(reload + 1)
    setApp(response.data)
  }
  const handleDescription = (e) => {
    setNewDescription(e.target.value)
  }
  useEffect(() => {
    console.log(app)
    setEditMode(false)
    setName(app ? app.name : '')
    setNameEn(app ? app.name_en : '')
    setNewName(app ? app.name : '')
    setNewNameEn(app ? app.name_en : '')
    setDescription(app ? app.description ? app.description : app.field ? app.field : '' : '')
    setDescriptionEn(app ? app.description_en ? app.description_en : app.field_en ? app.field_en : '' : '')
    setNewDescription(app ? app.description ? app.description : app.field ? app.field : '' : '')
    setNewDescriptionEn(app ? app.description_en ? app.description_en : app.field_en ? app.field_en : '' : '')
  }, [app])

  const content = (
    <div>
      <h1>Eliminar {name} </h1>
    </div>
  )
  return (
    <div>
      {editMode && (
        <div className={classes.container} id='info-card'>
          <TextField
            placeholder='Título - ES'
            label='Editar título'
            className={classes.textField}
            onChange={(e) => setNewName(e.target.value)}
            value={newName}
          />
          <br />
          <br />
          <TextField
            placeholder='Title - EN'
            label='Edit title'
            className={classes.textField}
            onChange={(e) => setNewNameEn(e.target.value)}
            value={newNameEn}
          />
          <Divider />
          <div className={classes.editor}>
            <p>Español</p>
            <TextEditor html={newDescription} setHtml={setNewDescription} />
          </div>
          <div className={classes.editor}>
            <p>Inglés</p>
            <TextEditor html={newDescriptionEn} setHtml={setNewDescriptionEn} />
          </div>
          <Grid container alignItems='flex-start' justify='flex-end' direction='row'>
            <div className={classes.btn}>
              <Button
                type='secondary'
                size='small'
                label='Cancelar'
                className={classes.btn}
                onClick={() => {
                  setEditMode(false)
                  setNewName(name)
                  setNewDescription(description)
                }}
              />
            </div>
            <div className={classes.btn}>
              <Button
                type='primary'
                size='small'
                className={classes.btn}
                label='Guardar'
                onClick={handleEdit}
              />
            </div>
          </Grid>
        </div>
      )}
      {!editMode && (
        <div className={classes.container} id='info-card'>
          <Modal
            key='simple-modal'
            open={open}
            onHandleButton={handleDelete}
            setOpen={setOpen}
            labelButton='Eliminar'
            content={content}
          />
          <Grid container alignItems='center'>
            <Grid item xs={12} lg={8} md={8}>
              <div className={classes.title}>
                <h1> {language === "es" ? name : nameEn} </h1>
              </div>
            </Grid>
            <Grid item xs={12} lg={4} md={4} className={classes.right}>
              {auth.currentUserRole && !(app ? app.services ? true : false : false) && (
                <div>
                  <IconButton className={classes.button} aria-label='Edit' onClick={() => setEditMode(true)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton className={classes.button} aria-label='Delete' onClick={() => setOpen(true)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </Grid>
          </Grid>
          <Divider />
          <div className={classes.body}>
            <span dangerouslySetInnerHTML={{ __html: sanitizer(language === "es" ? description : descriptionEn) }} />
          </div>
        </div>
      )}
    </div>
  )
}

export default InfoCard
