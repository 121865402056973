import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IntroContainer, ProductsContainer, LearnContainer, BeetrackInfoContainer } from './components'
const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: '"Open Sans"',
    color: '#3b4754',
    display: 'block',
    '& h1': {
      fontSize: '3.8em',
      margin: 0,
      lineHeight: '1.2em'
    },
    '& h2': {
      fontSize: '2.3em',
      margin: 0
    },
    '& h3': {
      fontSize: '1.7em',
      margin: 5
    },
    '& h4': {
      fontSize: '1.6em',
      color: '#798da3'
    },
    '& h5': {
      fontSize: '1.1em',
      fontWeight: '600',
      color: '#798da3'
    },
    '& h6': {
      fontSize: '1em',
      fontWeight: '600',
      color: '#798da3',
      fontStyle: 'italic'
    }
  },
  introContainer: {
    width: '85%',
    margin: 'auto'
  },
  subtitle: {
    marginTop: 10,
    maxWidth: '95%',
    fontWeight: '500'
  },
  subContainer: {
    paddingTop: '10%',
    width: '85%',
    margin: 'auto',
    minHeight: '520px'
  },
  titleLanding: {
    textAlign: 'left',
    paddingTop: '13%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10%'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '95%'
    }
  },
  imgPos: {
    paddingTop: '5%'
  },
  img: {
    height: '90%',
    width: '90%'
  },
  btnBig: {
    padding: '0px'
  },
  infoButtonLine: {
    width: '40%',
    marginTop: '2%'
  },
  learnButtonLine: {
    width: '35%',
    margin: 'auto',
    paddingTop: '5%'
  },
  buttonLine: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '4%'
    },
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  productGrid: {
    paddingTop: '10%'
  },
  productLogo: {
    height: '65px'
  },
  logoCont: {
    padding: '10%'
  },
  icon: {
    fontSize: '4em',
    color: '#FB8501'
  },
  iconL: {
    marginTop: '90%',
    fontSize: '3.3em',
    color: '#1b1b1b',
    [theme.breakpoints.down('sm')]: {
      margin: '10%',
      transform: 'rotate(90deg)'
    }
  }
}))
const Home = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <IntroContainer classes={classes} />
      <ProductsContainer classes={classes} />
      <BeetrackInfoContainer classes={classes} />
      <LearnContainer classes={classes} />
    </div>
  )
}

export default Home
