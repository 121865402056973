import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import { ApikeysCard } from './components'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { CircularProgress } from '../../../../components'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  logoTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
  },
  icon: {
    color: '#1b1b1b'
  },
  panel: {

  },
  expand: {
    transform: 'rotate(270deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(0deg)'
    }
  },
  expandOpen: {
    transform: 'rotate(90deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(180deg)'
    }
  }
}))

const ApikeysPanel = (props) => {
  const {
    setPanel,
    panel,
    handleOpenApikey,
    keys,
    handleCloseApikey,
    keysOpen,
    setKeysOpen,
    handleSelectWebhook,
    selectWebhooks,
    loadingKeys
  } = props
  const classes = useStyles()
  return (
    <div className={classes.panel}>
      <div className={classes.logoTitle}>
        {(panel > 1) && <h4>Apikeys</h4>}
        {(panel > 1) &&
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: (panel > 1)
            })}
            onClick={setPanel}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </IconButton>}
        {(panel <= 1) &&
          <IconButton
            onClick={setPanel}
            aria-label='show more'
          >
            <VpnKeyIcon className={classes.icon} />
          </IconButton>}
      </div>
      {(panel > 1) &&
        <Divider variant='middle' />}
      {loadingKeys && <CircularProgress />}
      {(panel > 1) &&
        <div className={classes.content}>
          {keys.map((key, i) => {
            return (
              <ApikeysCard
                handleOpenApikey={handleOpenApikey}
                handleCloseApikey={handleCloseApikey}
                key={i}
                id={i}
                keyName={key.key.substr(key.key.length - 8)}
                webhooks={key.webhooks}
                keysOpen={keysOpen}
                setKeysOpen={setKeysOpen}
                handleSelectWebhook={handleSelectWebhook}
                selectWebhooks={selectWebhooks}
              />
            )
          })}
        </div>}
    </div>
  )
}
export default ApikeysPanel
