import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '../../../../components'
import LMH from '../../../../assets/images/H LM.svg'
import PPH from '../../../../assets/images/H PP.svg'
import ODH from '../../../../assets/images/H OD.svg'
import FMH from '../../../../assets/images/H FM.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  card: {
    margin: 'auto',
    width: '100%'
  },
  icon: {
    paddingTop: '10%',
    width: '60%',
    height: '160px',
    margin: 'auto'
  },
  button: {
    paddingTop: '4%'
  },
  productLogo: {
    height: '100%',
    width: '100%'
  }
}))

const ProductCard = (props) => {
  const classes = useStyles()
  const { name, type, onClickDocs } = props
  const [t, i18n] = useTranslation("global")

  return (
    <div className={classes.card}>
      <div className={classes.icon}>
        {name === 1 && <img alt='landing-img' className={classes.productLogo} src={LMH} />}
        {name === 2 && <img alt='landing-img' className={classes.productLogo} src={PPH} />}
        {name === 3 && <img alt='landing-img' className={classes.productLogo} src={FMH} />}
        {name === 4 && <img alt='landing-img' className={classes.productLogo} src={ODH} />}
      </div>
      <div className={classes.button}>
        <Button onClick={() => onClickDocs(type)} label={t("dashboard.open")} type={type} size='medium' />
      </div>
    </div>
  )
}
export default ProductCard
