import React, { useState } from 'react'
import createUser from '../../utils/fetch/createUser'
import { Button, TextField } from '../../components'
import { makeStyles } from '@material-ui/styles'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '3%',
    alignItems: 'center'
  },
  title: {
    paddingTop: '1px',
    fontSize: '1.9em',
    color: '#3b4754'
  },
  card: {
    margin: 'auto',
    maxWidth: '40%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%'
    },
    backgroundColor: '#FFF',
    borderRadius: '20px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  text: {
    paddingTop: '20px'
  },
  warningText: {
    color: 'red'
  },
  container: {
    padding: '8%',
    paddingTop: '0'
  },
  captcha: {
    textAlign: 'center',
    display: 'inline-block'
  }
}))

const SignUp = props => {
  const classes = useStyles()
  const { history } = props
  const [captcha, setCaptcha] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(true)
  const [errorText, setErrorText] = useState('')
  const [values, setValues] = useState({ email: '', password: '', password2: '' })
  const handleSubmit = async (e) => {
    setLoading(true)
    console.log(values)
    const response = await createUser(values.email, values.password)
    console.log(response)
    if (response.data.id) {
      history.push('/')
    } else {
      setError(true)
      setErrorText('Email ya existe')
    }
    setLoading(false)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
    const validPassword = values.password === value
    const validPassword2 = values.password2 === value
    if (!values.password2 || !values.password || !values.email) return
    if ((!validPassword && (name === 'password2')) || (!validPassword2 && (name === 'password'))) {
      setError(true)
      setErrorText('Contraseñas no coinciden')
    } else {
      setErrorText('')
      if (captcha) return setError(false)
      setErrorText('Marcar captcha')
    }
  }
  function onChange (value) {
    setCaptcha(!captcha)
    if (values.password2 === values.password) setError(false)
  }
  const errorButton = () => {
    setErrorText('No se ha rellenado correctamente el formulario')
  }
  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.title}>
          <h3>Crear cuenta</h3>
        </div>
        <div className={classes.container}>
          <div className={classes.text}>
            <TextField
              key='email'
              onChange={handleInputChange}
              placeholder='Email'
              label='Mail'
              value={values.email}
              name='email'
            />
          </div>
          <div className={classes.text}>
            <TextField
              type='password'
              key='password'
              onChange={handleInputChange}
              placeholder='Contraseña'
              label='Contraseña'
              value={values.password}
              name='password'
            />
          </div>
          <div className={classes.text}>
            <TextField
              type='password'
              key='password'
              onChange={handleInputChange}
              placeholder='Contraseña'
              label='Confirme su contraseña'
              value={values.password2}
              name='password2'
            />
          </div>
          <div className={classes.text}>
            <div className={classes.captcha}>
              <ReCAPTCHA
                sitekey='6LcyI70ZAAAAAEdifcB_pF9eim5E41m11p__B4BX'
                onChange={onChange}
              />
              {error && <p className={classes.warningText}>{errorText}</p>}
            </div>
          </div>
          <div className={classes.text}>
            {(!loading && !error) && <Button size='signin' label='Crear Usuario' type='secondary' onClick={() => { handleSubmit() }} />}
            {(loading || error) && <Button size='signin' label='Ingresar' type='disabled' onClick={() => errorButton()} />}
          </div>
          <div className={classes.text}>
            <p>Tienes cuenta? <a href='/sign-in'>Inicia Sesión!</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
