import React, { useEffect, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import getApps from '../../../../utils/services/apps'
import { TextField, Modal, CircularProgress } from '../../../../components'
import TextEditor from '../../../../components/TextEditor'

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: '5%',
    marginLeft: '2%'
  },
  editor: {
    paddingTop: '2%'
  },
  paper: {
    position: 'absolute',
    top: '10%',
    left: '31%',
    width: '38%',
    backgroundColor: '#ffffff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  title: {
    textAlign: 'center'
  },
  row: {
    padding: '10px'
  },
  alignRight: {
    float: 'right'
  }
}))

const ModalInput = (props) => {
  const classes = useStyles()
  const [apps, setApps] = useState([])
  const [loading, setLoading] = useState(false)
  const handleChange = (event) => {
    props.setNewId(event.target.value)
  }
  useEffect(() => {
    async function fetchData () {
      // You can await here
      setLoading(true)
      const result = await getApps()
      setApps(result.data)
      setLoading(false)
      // ...
    }
    fetchData()
  }, [props.reload])

  const content = (
    <div>
      {!loading &&
        <div>
          <div className={classes.title}>
            <h1>Nuevo Servicio</h1>
          </div>
          <div className={classes.divider}>
            <Divider />
          </div>
          <TextField
            placeholder='Título'
            label='Título del servicio'
            value={props.newName}
            onChange={(e) => props.newNameFunc(e.target.value)}
            name='newkey'
          />
          <br />
          <br />
          <TextField
            placeholder='Title'
            label='Title of the service'
            value={props.newNameEn}
            onChange={(e) => props.newNameEnFunc(e.target.value)}
            name='newkey'
          />
          <div className={classes.editor}>
            <p> Español </p>
            <TextEditor html={props.newDescription} setHtml={props.newDescriptionFunc} />
          </div>
          <div className={classes.editor}>
            <p> Inglés </p>
            <TextEditor html={props.newDescriptionEn} setHtml={props.newDescriptionEnFunc} />
          </div>
          <div className={classes.select}>
            <InputLabel id='demo-simple-select-label'>Aplicación</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='select-app'
              value={props.id}
              onChange={handleChange}
            >
              {apps.map((app, i) => {
                return (
                  <MenuItem value={app.id} key={i}> {app.name}</MenuItem>
                )
              })}
            </Select>
          </div>
        </div>}
      {loading &&
        <CircularProgress />}
    </div>)

  return (
    <Modal
      key='simple-modal'
      open={props.open}
      setOpen={props.openFunc}
      labelButton='Guardar'
      content={content}
      onHandleButton={props.handleClickFunc}
    />
  )
}
export default ModalInput
