import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { PieChart, Pie, Cell, Sector } from 'recharts'
const useStyles = makeStyles({
  center: {
    margin: 'auto'
  },
  title: {
    fontWeight: 'bold'
  }
})
const COLORS = ['#B3DA05', '#0191B6', '#FB8501', '#FF3E50']

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 5) * cos
  const sy = cy + (outerRadius + 5) * sin
  const mx = cx + (outerRadius + 20) * cos
  const my = cy + (outerRadius + 20) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4}
        outerRadius={outerRadius + 8}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  )
}

const cleanData = (data) => {
  var clean = []
  for (const line of data) {
    if (line.value !== 0) {
      clean.push(line)
    }
  }
  return clean
}

export default function Graph (props) {
  const classes = useStyles()
  const [index, setIndex] = useState(0)
  const [realData, setRealData] = useState([{ name: 'data', value: 0 }])
  const {
    data,
    title
  } = props

  const onPieEnter = (data, index) => {
    setIndex(index)
  }

  useEffect(() => {
    const finalData = cleanData(data)
    setRealData(finalData)
  }, [data])
  return (
    <div>
      <div className={classes.title}>
        <p>{title}</p>
      </div>
      <PieChart className={classes.center} width={430} height={300}>
        <Pie
          data={realData}
          activeIndex={index}
          onMouseEnter={onPieEnter}
          activeShape={renderActiveShape}
          dataKey='value'
          nameKey='name'
          outerRadius={100}
          fill='#82ca9d'
          paddingAngle={0}
          innerRadius={80}
          viewBox={500}
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    </div>
  )
}
