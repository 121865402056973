import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer
} from 'recharts'

const useStyles = makeStyles({
  container: {
    overflow: 'visible',
    margin: 'auto'
  },
  title: {
    fontWeight: 'bold'
  }
})

export default function Graph (props) {
  const classes = useStyles()
  const { data, whs, product } = props
  return (
    <div>
      <div className={classes.title}>
        {data.length === 0 ? <p>No se encontraron hits en este tramo</p> : <p>Últimos hits</p>}
      </div>
      {data.length > 0 &&
        <ResponsiveContainer className={classes.container} width='95%' height={250}>
          <BarChart data={data}>
            {whs.length !== 0 && <CartesianGrid strokeDasharray='3 3' />}
            <XAxis dataKey='name'>
              {whs.length === 0 && <Label value='Seleccione webhooks para visualizar' offset={100} position='top' />}
            </XAxis>
            <YAxis />
            <Tooltip />
            <Bar dataKey='value' fill={product === 'LM' ? '#FB8501' : '#B3DA05'} />
          </BarChart>
        </ResponsiveContainer>}
    </div>
  )
}
