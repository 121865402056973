import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AppsIcon from '@material-ui/icons/Apps'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ForumIcon from '@material-ui/icons/Forum'
import List from '@material-ui/core/List'
import SecurityIcon from '@material-ui/icons/Security'
import Divider from '@material-ui/core/Divider'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import DescriptionIcon from '@material-ui/icons/Description'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { makeStyles } from '@material-ui/core/styles'
import CodeIcon from '@material-ui/icons/Code'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Beetrack from '../../../../assets/images/BEE HN.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  iconList: {
    justifyContent: 'center'
  },
  productLogo: {
    width: '200px',
    cursor: 'pointer',
    maxHeight: '50px'
  }
}))

const DrawerBar = (props) => {
  const { history, auth, handleLogout, handleLogin } = props
  const classes = useStyles()
  const [t, i18n] = useTranslation("global")
  return (
    <div>
      <List>
        <ListItem className={classes.iconList} button onClick={() => history.push('/')}>
          <ListItemIcon><img alt='landing-img' onClick={() => history.push('/')} className={classes.productLogo} src={Beetrack} /></ListItemIcon>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => history.push('/services')}>
          <ListItemIcon><AppsIcon /></ListItemIcon>
          <ListItemText primary={t("menu.products")} />
        </ListItem>
        <ListItem button onClick={() => history.push('/cases')}>
          <ListItemIcon><DescriptionIcon /></ListItemIcon>
          <ListItemText primary={t("menu.user-manual")} />
        </ListItem>
        <ListItem button onClick={() => history.push('/docs')}>
          <ListItemIcon><CodeIcon /></ListItemIcon>
          <ListItemText primary={t("menu.api-documentation")} />
        </ListItem>
        <ListItem button onClick={() => history.push('/dashboard')}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={t("menu.dashboard")} />
        </ListItem>
        <ListItem button onClick={() => history.push('/community')}>
          <ListItemIcon><ForumIcon /></ListItemIcon>
          <ListItemText primary={t("menu.community")} />
        </ListItem>
        <ListItem button onClick={() => history.push('/faq')}>
          <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
          <ListItemText primary='FAQ' />
        </ListItem>
      </List>
      <Divider />

      {auth.loggingIn && (
        <List>
          <ListItem button onClick={() => history.push('/profile')}>
            <ListItemIcon> <AccountCircle /></ListItemIcon>
            <ListItemText primary={auth.currentEmail.split('@')[0]} />
          </ListItem>
          {auth.currentUserRole &&
            <ListItem button color='inherit' onClick={() => history.push('/adminpanel')}>
              <ListItemIcon> <SecurityIcon /></ListItemIcon>
              <ListItemText primary='Admin' />
            </ListItem>}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon> <ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={t("menu.logout")} />
          </ListItem>
        </List>
      )}{!auth.loggingIn && (
        <List>
          <ListItem button onClick={handleLogin}>
            <ListItemIcon> <SupervisorAccountIcon /></ListItemIcon>
            <ListItemText primary={t("menu.login")} />
          </ListItem>
        </List>
      )}
    </div>
  )
}
export default DrawerBar
