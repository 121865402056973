import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LogTable, Graph } from './components'

const useStyles = makeStyles({
  barChart: {
    width: '100%',
    margin: 'auto',
    minHeight: 300
  },
  logsTab: {
    textAlign: 'left',
    marginTop: 20,
    paddingLeft: 20
  }
})


export default function Logs (props) {
  const classes = useStyles()
  const {
    data,
    whs,
    logsData,
    product
  } = props
  return (
    <div>
      <div className={classes.barChart}>
        <Graph
          data={data}
          whs={whs}
          product={product}
        />
      </div>
      <div className={classes.logsTab}>
        <div className={classes.logs}>
          <LogTable logsData={logsData} product={product} />
        </div>
      </div>
    </div>
  )
}
