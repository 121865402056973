import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  circularStyle: {
    color: '#f5b63e'
  }
}))

const ModalB = (props) => {
  const classes = useStyles()
  return (
    <CircularProgress className={classes.circularStyle} />
  )
}

export default ModalB
