import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { MenuServices, NewServiceNew, InfoCard } from './components'
import createService from '../../utils/services/createService'
import { useSelector } from 'react-redux'
import getApps from '../../utils/services/apps'
import { CircularProgress } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    fontFamily: '"Open Sans"',
    height: '100vh'
  },
  gridItem: {
    padding: 10
  },
  menu: {
    position: 'sticky',
    textAlign: 'left',
    marginLeft: '3px'

  },
  text: {
    padding: '15px'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  logoTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    marginTop: '5%',
    marginLeft: '5%'
  },
  infoCard: {
    width: '95%',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '3%'
    }
  }
}))

const Services = (props) => {
  const classes = useStyles()
  const [apps, setApps] = useState([])
  const [app, setApp] = useState(null)
  const [reload, setReload] = useState(0)
  const [newName, setNewName] = useState('')
  const [newNameEn, setNewNameEn] = useState('')
  const [newId, setNewId] = useState(1)
  const [newDescription, setNewDescription] = useState('')
  const [newDescriptionEn, setNewDescriptionEn] = useState('')
  const [openNew, setOpenNew] = useState(false)
  const [loading, setLoading] = useState(true)
  const auth = useSelector(state => state.auth)
  const { match: { params } } = props

  const handleNew = async () => {
    await createService(auth.currentToken, newName, newNameEn, newDescription, newDescriptionEn, newId)
    setOpenNew(false)
    setNewName('')
    setNewNameEn('')
    setNewNameEn('')
    setNewDescription('')
    setNewDescriptionEn('')
    setNewDescriptionEn('')
    setNewId(1)
    setReload(reload + 1)
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const result = await getApps()
      const data = result.data
      data.sort(function (a, b) {
        return (-(b.id - a.id || a.name.localeCompare(b.name)))
      })
      setApps(data)
      if (reload === 0) {
        if (params.id) {
          if (params.id === 'lastmile') setApp(data[0])
          if (params.id === 'plannerpro') setApp(data[1])
          if (params.id === 'firstmile') setApp(data[2])
          if (params.id === 'ondemand') setApp(data[3])
        } else {
          setApp(data[0])
        }
      }
      setLoading(false)
      // ...
    }
    fetchData()
  }, [reload, params.id])

  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          <Grid
            container
            direction='row'
            spacing={0}
            justify='space-evenly'
          >
            <Grid item xs={12} lg={2} className={classes.gridItem}>
              <div className={classes.menu}>
                <MenuServices
                  app1={app}
                  apps={apps}
                  reload={reload}
                  setOpenNew={setOpenNew}
                  setApp={setApp}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={10}>
              <div className={classes.infoCard}>
                <InfoCard app={app} reload={reload} setReload={setReload} setApp={setApp} />
              </div>
            </Grid>
          </Grid>
          <NewServiceNew
            open={openNew}
            openFunc={setOpenNew}
            newNameEnFunc={setNewNameEn}
            newNameFunc={setNewName}
            newDescriptionFunc={setNewDescription}
            newDescriptionEnFunc={setNewDescriptionEn}
            newName={newName}
            newNameEn={newNameEn}
            newDescription={newDescription}
            newDescriptionEn={newDescriptionEn}
            handleClickFunc={handleNew}
            id={newId}
            setNewId={setNewId}
          />
        </div>
      )}
      {loading && (
        <center>
          <CircularProgress />
        </center>
      )
      }
    </div>
  )
}

export default Services
