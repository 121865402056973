import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextFieldMUI from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#1b1b1b'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#f5b63e'
      }
    }
  },
  input: {
    color: 'black'
  },
  inputPop: {
    height: '36px',
    color: 'black'
  }
}))

const TextField = (props) => {
  const {
    key,
    onChange,
    type,
    placeholder,
    label,
    value,
    name,
    pop
  } = props
  const classes = useStyles()
  
  const normalInput = {
    className: classes.input
  }
  
  const popOver = {
    className: classes.inputPop
  }
  return (
    <TextFieldMUI
      key={key}
      className={classes.textField}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      type={type}
      variant='outlined'
      value={value}
      name={name}
      InputProps={pop ? popOver : normalInput}
    />)
}

export default TextField
