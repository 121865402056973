import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { useSelector } from 'react-redux'
import Button from '../../../../components/Button'
import TextField from '../../../../components/TextField'
import { makeStyles } from '@material-ui/styles'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import withWidth from '@material-ui/core/withWidth'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3%',
    alignItems: 'center'
  },
  field: {
    paddingTop: '5%'
  }
}))

const Password = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const { width } = props
  const [t, i18n] = useTranslation("global")

  return (
    <Container className={props.classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div>
            <h3>{t("profile.account-configuration")}</h3>
            <br />
            <Avatar className={props.classes.amber}> {auth.currentEmail.charAt(0).toUpperCase()}</Avatar>
            <br />
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.field}>
            <TextField
              placeholder={t("profile.email")}
              label={t("profile.email")}
              value={props.values.email}
              onChange={props.handleInputChange}
              name='email'
              disabled
            />
          </div>
          <div className={classes.field}>
            <TextField
              label={t("profile.actual-password")}
              type='password'
              value={props.values.password_actual}
              onChange={props.handleInputChange}
              name='password_actual'
            />
          </div>
          <div className={classes.field}>
            <TextField
              label={t("profile.new-password")}
              type='password'
              onChange={props.handleInputChange}
              value={props.values.password}
              name='password'
            />
          </div>
          <div className={classes.field}>
            <TextField
              label={t("profile.repeat-password")}
              type='password'
              onChange={props.handleInputChange}
              value={props.values.password2}
              name='password2'
            />
          </div>
          <br />
          <div className={props.classes.warningText}>
            {props.error ? props.errorText : null}
          </div>
          {(!props.loading && !props.error) && (<Button label={t("profile.change-password")} type='secondary' size='medium' onClick={() => { props.handleSubmit() }} />)}
          {(props.loading || props.error) && <Button label={t("profile.change-password")} type='disabled' size='medium' />}
        </Grid>
      </Grid>
    </Container>
  )
}
export default withWidth()(Password)
