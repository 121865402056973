import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '../../../../components'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import FMSVG from '../../../../assets/images/H FM.svg'
import LMSVG from '../../../../assets/images/H LM.svg'
import ODSVG from '../../../../assets/images/H OD.svg'
import PPSVG from '../../../../assets/images/H PP.svg'
import clsx from 'clsx'
import { MenuItem } from '@material-ui/core'
import sendJson from '../../../../utils/fetch/sendJson'
import { useSelector } from 'react-redux'
import getApps from '../../../../utils/services/apps'
import handleLogout from '../../../../utils/connect/logout'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    color: '#3b4754',
    paddingLeft: '2%',
    '& h1': {
      fontSize: '2.3em',
      fontWeight: '800'
    },
    '& h3': {
      fontSize: '1.3em',
      fontWeight: '500'
    },
    '& h4': {
      fontSize: '1.0em'
    }
  },
  logoTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
  },
  productLogo: {
    width: '200px'
  },
  actions: {
    paddingTop: '2%',
    width: '100%',
    display: 'flex'
  },
  button: {
    paddingLeft: '4%'
  },
  selecCont: {
    paddingTop: '2%'
  },
  sendCont: {
    paddingTop: '6%'
  },
  input: {
    display: 'none'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }

}))
const Apps = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const [type, setType] = useState('1')
  const [expandedLast, setExpandedLast] = useState(false)
  const [nameFile, setNameFile] = useState('')
  const [file, setFile] = useState(null)
  const [upload, setUpload] = useState(false)
  const [err, setErr] = useState(false)
  const [apps, setApps] = useState([])
  const { history } = props

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const result = await getApps()
      const data = result.data
      data.sort(function (a, b) {
        return (-(b.id - a.id || a.name.localeCompare(b.name)))
      })
      setApps(data)
      // ...
    }
    fetchData()
  }, [])
  const handleExpandLastClick = () => {
    setExpandedLast(!expandedLast)
  }
  const handleType = (e) => {
    setType(e.target.id)
    setExpandedLast(!expandedLast)
  }

  function onChangeHandler (event) {
    setUpload(false)
    setErr(false)
    var reader = new FileReader()
    reader.onload = onReaderLoad
    reader.readAsText(event.target.files[0])
    setNameFile(event.target.files[0].name)
  }
  function onReaderLoad (event) {
    var obj = JSON.parse(event.target.result)
    setFile(obj)
  }

  const uploadFile = async () => {
    try {
      await sendJson(file, type, auth.currentToken)
      setUpload(true)
    } catch (error) {
      if (error.response.status) {
        handleLogout(history)
      } else {
        setErr(true)
      }
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h1>Referencia API</h1>
        <h3>
          Desde aquí podrás cambiar el archivo json que se utiliza para
          representar cada referencia API de los productos
        </h3>
      </div>
      <div className={classes.selecCont}>
        <h4>Seleccione el producto</h4>
        <div className={classes.actions}>
          <div className={classes.logo}>
            <div className={classes.logoTitle}>
              {type === '1' && <img alt='landing-img' className={classes.productLogo} src={LMSVG} />}
              {type === '2' && <img alt='landing-img' className={classes.productLogo} src={PPSVG} />}
              {type === '3' && <img alt='landing-img' className={classes.productLogo} src={FMSVG} />}
              {type === '4' && <img alt='landing-img' className={classes.productLogo} src={ODSVG} />}
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expandedLast
                })}
                onClick={handleExpandLastClick}
                aria-expanded={expandedLast}
                aria-label='show more'
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
            <Collapse in={expandedLast} timeout='auto' unmountOnExit>
              {apps.map((app, k) => {
                if (type !== app.id) {
                  return (
                    <MenuItem key={k} id={app.id} onClick={handleType}>{app.name}</MenuItem>
                  )
                } else {
                  return (<></>)
                }
              }
              )}
            </Collapse>
          </div>
          <div className={classes.button}>
            <input
              accept='.json'
              className={classes.input}
              id='contained-button-file'
              multiple
              type='file'
              onChange={onChangeHandler}
            />
            <label htmlFor='contained-button-file'>
              <Button size='medium' label='Seleccionar archivo' type='secondary' component='span' />
            </label>
          </div>
          <div className={classes.button}>
            <p>{nameFile && ('Se cargó el archivo: ' + nameFile)}</p>
          </div>
        </div>
        <div className={classes.sendCont}>
          <Button size='medium' label='Subir' type='primary' onClick={uploadFile} />
          {(upload && !err) && <p>El archivo {nameFile} se ha subido correctamente</p>}
          {(upload && err) && <p>Hubo un error</p>}
        </div>
      </div>
    </div>
  )
}
export default Apps
