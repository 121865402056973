import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import postLikePage from '../../../utils/fetch/likePage'
import postDislikePage from '../../../utils/fetch/dislikePage'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
      height: theme.spacing(16)
    },
    marginTop: '5%'
  },
  content: {
    paddingTop: '1%'
  }
}))

const LikePage = (props) => {
  const classes = useStyles()
  const [voted, setVoted] = useState(0)
  const { id } = useParams()

  const likePage = async () => {
    setVoted(1)
    await postLikePage(id)
  }

  const dislikePage = async () => {
    setVoted(2)
    await postDislikePage(id)
  }

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div className={classes.content}>
          <h4>
            ¿Fue útil esta página?
          </h4>
          <IconButton aria-label='like' disabled={voted !== 0 ? true : false} onClick={() => likePage()}>
            <ThumbUpIcon />
          </IconButton>
          <IconButton aria-label='dislike' disabled={voted !== 0 ? true : false} onClick={() => dislikePage()}>
            <ThumbDownIcon />
          </IconButton>
          <p> {voted === 1 ? 'Gracias por tu feedback' : voted === 2 ? 'Gracias por el feedback. Si necesitas más ayuda contacta a soporte@beetrack.com' : ''}</p>
        </div>
      </Paper>
    </div>
  )
}

export default LikePage
