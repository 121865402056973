import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { EditCard, NormalCard } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    alignItems: 'center'
  },

  card: {
    padding: '10px',
    paddingLeft: '20px',
    margin: 'auto',
    marginTop: '10px',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  menu: {
    textAlign: 'left',
    marginLeft: '3px'
  },
  text: {
    padding: '15px'
  },
  warningText: {
    color: 'red'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  logoTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    color: '#4E5B6C',
    textAlign: 'justify'
  },
  textField: {
    minWidth: '100%',
    padding: 100
  },
  buttons: {
    textAlign: 'right',
    padding: 10,
    width: '100%'
  },
  btn: {
    display: 'inline-block',
    padding: 10
  }
}))

const QuestionCard = (props) => {
  const {
    title, description,
    handleDelete,
    cardId,
    admin,
    handleEdit
  } = props
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)

  const handleSubmit = async (newTitle, newDescription) => {
    await handleEdit(cardId, newTitle, newDescription)
    setEditMode(!editMode)
  }
  const cancelEditMode = e => {
    setEditMode(!editMode)
  }

  return (
    <div className={classes.card}>
      {editMode &&
        <EditCard
          classes={classes}
          cancelEditMode={cancelEditMode}
          handleSubmit={handleSubmit}
          handleEdit={handleEdit}
          cardId={cardId}
          title={title}
          description={description}
        />}
      {!editMode &&
        <NormalCard
          classes={classes}
          title={title}
          description={description}
          handleDelete={handleDelete}
          admin={admin}
          cancelEditMode={cancelEditMode}
          cardId={cardId}
        />}
    </div>
  )
}
export default QuestionCard
