import axios from 'axios'
import { URL } from '../url'

export default async function getWebhooks (token, product) {
  const body = {
    product
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.post(URL + '/dashboard/webhooks', body, headers)
  return response
}
