import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  button: {
    width: '100%',
    textAlign: 'left'
  },
  lower: {
    textTransform: 'lowercase'
  }
})

export default function ApikeysCard (props) {
  const classes = useStyles()
  const { keyName } = props
  return (
    <div className={classes.button}>
      <Button className={classes.lower}>
        {keyName}
      </Button>
    </div>
  )
}
