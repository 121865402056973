import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import getCategoryArticles from '../../utils/community/CategoryArticles'
import deleteCategory from '../../utils/fetch/deleteCategory'
import { useParams } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ForumIcon from '@material-ui/icons/Forum'
import { Button, CircularProgress, TextField } from '../../components'
import { useSelector } from 'react-redux'
// import DOMPurify from 'dompurify'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import FavoriteIcon from '@material-ui/icons/Favorite'
import Pagination from '@material-ui/lab/Pagination'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%'
  },
  text: {
    textAlign: 'justify'
  },
  btn: {
    textAlign: 'right'
  },
  btnDelete: {
    padding: '5px',
    textAlign: 'left'
  },
  buttonLine: {
    paddingTop: '20px'
  },
  editBtn: {
    paddingTop: '3%',
    textAlign: 'left'
  }
}))

const ArticlesCategory = (props) => {
  const classes = useStyles()
  const { id, name } = useParams()
  const { history } = props
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [maxPages, setMaxPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const auth = useSelector(state => state.auth)
  const [t, i18n] = useTranslation("global")
  // const sanitizer = DOMPurify.sanitize

  const viewArticle = (articleId) => {
    history.push('/community/' + articleId.toString())
  }

  const deleteCategoryFunc = () => {
    deleteCategory(auth.currentToken, id)
    history.push('/community')
  }
  const newEntry = () => {
    history.push('/community/new/' + name + '/' + id.toString())
  }

  const logIn = () => {
    history.push('/sign-in')
  }
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const searchElement = () => {
    history.push('/community/search/' + name + '/' + id.toString() + '/' + search.toString())
  }

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const result = await getCategoryArticles(id, currentPage)
      setMaxPages(result.data.pages)
      setArticles(result.data.data)
      setLoading(false)
      // ...
    }
    fetchData()
  }, [currentPage, id])
  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          <div className={classes.text}>
            <h1> {name} </h1>
          </div>
          {auth.loggingIn && (
            <div className={classes.btnDelete}>
              <Button
                label={t("community.delete-category")}
                type='secondary'
                size='medium'
                onClick={() => deleteCategoryFunc()}
              />
            </div>)}
          <div className={classes.searchBox}>
            <Grid container spacing={1}>
              <Grid item xs={9} sm={11}>
                <TextField
                  value={search}
                  placeholder={t("community.search")}
                  label={t("community.search")}
                  onChange={(e) => setSearch(e.target.value)}
                  name='search'
                />
              </Grid>
              <Grid item xs={3} sm={1}>
                <IconButton onClick={searchElement}>
                  <SearchIcon fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <List className={classes.root}>
            {articles.map((article, k) =>
              <ListItem button key={k} onClick={() => viewArticle(article.id)}>
                <ListItemAvatar>
                  <ForumIcon />
                </ListItemAvatar>
                <ListItemText primary={<div> <h4>{article.title}</h4> </div>} secondary={<p>{moment(article.created_at).format('DD/MM/YYYY')}</p>} />
                <Grid container alignItems='flex-start' justify='flex-end' direction='row'>
                  <FavoriteIcon />
                  <span> {article.likes} </span>
                </Grid>
              </ListItem>)}
          </List>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Pagination count={maxPages} variant='outlined' shape='rounded' page={currentPage} onChange={handlePageChange} />
            </Grid>
            <Grid item xs={6}>
              {auth.loggingIn && (id === '1' ? auth.currentUserRole : true) && (
                <div className={classes.btn}>
                  <Button label={t("community.create-post")} type='secondary' size='medium' onClick={() => newEntry()} />
                </div>
              )}
              {!auth.loggingIn && id !== '1' && (
                <div className={classes.btn}>
                  <Button label={t("community.search")} type='secondary' size='medium' onClick={() => logIn()} />
                </div>
              )}
            </Grid>
          </Grid>
          <div className={classes.editBtn}>
            <Button onClick={() => history.push('/community')} label={t("community.back")} type='secondary' size='small' />
          </div>
        </div>
      )}
      {loading && (
        <CircularProgress />
      )}
    </div>
  )
}

export default ArticlesCategory
