import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import ButtonWh from './button'
import { setLargeWh } from '../../../../helpers'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: '10%'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  webhook: {
    padding: 5,
    textTransform: 'lowercase'
  },
  buttonWh: {
    padding: '0.5%'
  },
  webhookPopUp: {
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'auto',
    borderRadius: 4,
    [theme.breakpoints.only('lg')]: {
    },
    backgroundColor: '#ffffff'
  },
  inlineItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
  }
}))

export default function ApikeysCard (props) {
  const classes = useStyles()
  const {
    keyName,
    handleOpenApikey,
    handleCloseApikey,
    id,
    setKeysOpen,
    keysOpen,
    handleSelectWebhook,
    selectWebhooks,
    webhooks
  } = props
  const [expand, setExpand] = useState(false)
  const setPanel = () => {
    var listKeys = keysOpen
    listKeys[id] = !expand
    setKeysOpen(listKeys)
    if (!expand) {
      handleOpenApikey()
    } else {
      handleCloseApikey()
    }
    setExpand(!expand)
  }
  useEffect(() => {
    if (!keysOpen[id]) return setExpand(false)
  }, [keysOpen, id])

  return (
    <div key={keyName}>
      <div className={classes.inlineItems}>
        <p>{keyName}</p>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expand
          })}
          onClick={setPanel}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={expand} timeout='auto' unmountOnExit>
        <div className={classes.webhookPopUp}>
          {webhooks.map((webhook, i) => {
            return (
              <div
                className={classes.buttonWh}
                key={i}
              >
                <ButtonWh
                  webhook={setLargeWh(webhook)}
                  handleSelectWebhook={handleSelectWebhook}
                  selectWebhooks={selectWebhooks}
                />
              </div>
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}
