import axios from 'axios'
import { URL } from '../url'

export default async function getCategoryArticles (id, currentPage) {
  const headers = {
    headers: {
    }
  }

  const response = await axios.get(URL + '/categories/' + id.toString() + '/articles?page=' + currentPage.toString(), headers)
  return response
}
