const initialState = {
  currentUserRole: null,
  currentToken: null,
  loggingIn: null,
  currentId: null
}

function authReducer (state = initialState, action) {
  switch (action.type) {
    case 'LOG_IN':
      return {
        currentToken: action.payload.accessToken,
        currentUserRole: action.payload.role,
        currentEmail: action.payload.email,
        currentId: action.payload.id,
        loggingIn: true
      }
    case 'NO_LOGGING':
      return {
        ...state,
        loggingIn: true
      }
    case 'LOG_OUT':
      return {
        currentToken: null,
        currentUserRole: null,
        loggingIn: false,
        currentEmail: null,
        currentId: null
      }

    default:
      return state
  }
}

export default authReducer
