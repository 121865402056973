import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField } from '../../../../components'

const useStyles = makeStyles(theme => ({
  searchBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    alignItems: 'center',
    margin: 'auto',
    borderRadius: '5px'
  },
  card: {
    padding: 15,
    margin: 'auto',
    textAlign: 'left',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  button: {
    paddingTop: 10,
    textAlign: 'right'
  },
  textField: {
    width: '100%',
    borderColor: 'yellow'
  },
  row: {
    padding: '15px'
  }
}))

const NewFAQ = (props) => {
  const classes = useStyles()
  const {
    handleTitle,
    handleDescription,
    newTitleFAQ,
    newDescriptionFAQ,
    handleSubmitNewFaq
  } = props

  return (
    <div className={classes.searchBar}>
      <div className={classes.card}>
        <h2>Agregar FAQ</h2>
        <div className={classes.row}>
          <TextField
            className={classes.textField}

            label='Agregar Título'
            placeholder='Escribe aquí el título del nuevo FAQ a agregar'
            onChange={(e) => handleTitle(e.target.value)}
            value={newTitleFAQ}
          />
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.textField}
            textArea
            label='Agregar respuesta'
            placeholder='Escribe aquí la respuesta del nuevo FAQ a agregar'
            onChange={(e) => handleDescription(e.target.value)}
            value={newDescriptionFAQ}
          />
        </div>
        <div className={classes.button}>
          <Button
            alignSelf='flex-end'
            type='primary'
            size='small'
            label='Agregar'
            onClick={handleSubmitNewFaq}
          />
        </div>
      </div>
    </div>
  )
}
export default NewFAQ
