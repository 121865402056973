import React, { useState, useEffect } from 'react'
import {
  useHistory
} from 'react-router-dom'

import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles'
import { Menu, Footer } from './layouts'
import './App.scss'
import Routes from './routes'
import TagManager from 'react-gtm-module'

const useStyles = makeStyles(theme => ({
  app: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
    minHeight: '100vh',
    fontFamily: '"Open Sans"'
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(10)
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8)
    }
  },
  contentT: {
    paddingTop: '90px'
  }
}))

const getPath = (path) => {
  return (path.split('/')[1])
}
function App () {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const [actualPage, setActualPage] = useState('')
  // Elementos par ahacer que el menu cambie
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-W39D2B6' })
    setActualPage(getPath(window.location.pathname))
  }, [])
  history.listen((location, action) => {
    setActualPage(getPath(location.pathname))
    window.scrollTo(0, 0)
  })
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <div className={classes.app}>
          <div className='App-bar'>
            <Menu actualPage={actualPage} />
          </div>
          <div className={classes.contentT}>
            <Routes />
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default App
