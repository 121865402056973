import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import getCategories from '../../../utils/community/Categories'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { CircularProgress } from '../../../components'
import ViewWeekIcon from '@material-ui/icons/ViewWeek'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360
  },
  menuButton: {
    marginRight: theme.spacing(2)
  }
}))

const Categories = (props) => {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { history } = props
  const [t, i18n] = useTranslation("global")

  const viewCategory = (id, name) => {
    history.push('/community/categories/' + name + '/' + id.toString())
  }
  useEffect(() => {
    async function fetchData () {
      try {
        const result = await getCategories()
        setCategories(result.data)
        setLoading(false)
      } catch (error) {
        if (error.response.status === 404) {
          setLoading(false)
        }
      }
    }
    fetchData()
  }, [])
  return (
    <div>
      {!loading && categories.length > 0 && (
        <div>
          <p style={{ textAlign: 'left' }}> {t("community.categories")} </p>
          <List className={classes.root}>
            {categories.map((category, k) =>
              <ListItem button key={k} onClick={() => viewCategory(category.id, category.name)}>
                <ListItemAvatar>
                  <ViewWeekIcon style={{ color: category.color }} />
                </ListItemAvatar>
                <ListItemText primary={category.name} secondary={category.description} />
              </ListItem>)}
          </List>
        </div>
      )}
      {!loading && categories.length === 0 && (
        <div>
          <p> {t("community.missing-categories")} </p>
        </div>
      )}
      {loading && (
        <CircularProgress />
      )}
    </div>
  )
}

export default Categories
