import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import disconnect from '../../utils/fetch/disconnect'
import { logOut } from '../../redux/actions/authActions'
import { DrawerBar, TopBar } from './components'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}))

function Menu (props) {
  const { window, actualPage } = props
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleLogout = async () => {
    await disconnect(auth.currentToken)
    dispatch(logOut())
    setMobileOpen(false)
    history.push('/')
  }

  const handleLogin = () => {
    if (auth.loggingIn) {
      history.push('/sign-out')
    } else {
      history.push('/sign-in')
    }
    setMobileOpen(false)
  }

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar
        actualPage={actualPage}
        handleDrawerToggle={handleDrawerToggle}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        auth={auth}
        history={history}
      />
      <nav className={classes.drawer} aria-label='mailbox folders'>
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {
              <DrawerBar
                history={history}
                auth={auth}
                handleLogout={handleLogout}
                handleLogin={handleLogin}
              />
            }
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

Menu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default Menu
