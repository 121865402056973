import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import '../../assets/css/theme-flattop.css'
import { URL } from '../../utils/url'
import { NotFound as NotFoundView } from '../../views'
import getDocs from '../../utils/fetch/getDocs'
import { CircularProgress } from '../../components'
import { RedocStandalone } from 'redoc';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%',
    textAlign: 'left'
  },
  iframe: {
    padding: '1%',
    textAlign: 'left',
    height: '90vh'
  }
}))

const LastMileDoc = (props) => {
  const classes = useStyles()
  const { match: { params } } = props
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (params.name === "1" || params.name === "lastmile") {
      setUrl('https://apidoc.beetrack.com/')
    } else if (params.name === "2" || params.name === "planner") {
      setUrl("https://apidocs.planner.beetrack.com/")
    }
    setLoading(false)
  }, [params.name])

  if (loading) {
    return (<CircularProgress />)
  } else {
    if (url !== '') {
      return (
        <div className={classes.iframe}>
          <br />
          <iframe className="unique" src={`${url}${props.location.hash}`} title="Documentation" style={{ overflow: 'hidden', height: '100%', width: '100%' }} frameborder="0" width="100%" height="100%" />
        </div>
      )
    } else {
      return (<NotFoundView />)
    }
  }
}

export default LastMileDoc