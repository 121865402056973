import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import getUsers from '../../../../../../utils/fetch/users'
import blockUser from '../../../../../../utils/fetch/blockUser'
import unblockUser from '../../../../../../utils/fetch/unblockUser'
import deleteUser from '../../../../../../utils/fetch/deleteUser'
import { useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import BlockIcon from '@material-ui/icons/Block'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Tooltip from '@material-ui/core/Tooltip'
import { NewEditUser, NewConfirmation } from '..'
import handleLogout from '../../../../../../utils/connect/logout'

const columns = [
  { id: 'id', label: 'Id', minWidth: 80 },
  { id: 'email', label: 'Email', maxWidth: 40 },
  { id: 'admin', label: 'Admin', minWidth: 40 },
  { id: 'blocked', label: 'Blocked', minWidth: 40 },
  { id: 'actions', label: 'Acciones', minWidth: 250, align: 'center' }
]

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: 'auto'
  },
  container: {
    maxHeight: 600
  },
  row: {
    maxHeight: 10
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4
  }
})

export default function StickyHeadTable (props) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [users, setUsers] = useState([])
  const authToken = useSelector(state => state.auth.currentToken)
  const [eid, setEid] = useState(null)
  const [did, setDid] = useState(null)
  const [deleteEmail, setDeleteEmail] = useState('')
  const [openNew, setOpenNew] = useState(false)
  const [deleteOpenNew, setDeleteOpenNew] = useState(false)
  const [reload, setReload] = useState(0)
  const { history } = props

  const getSetUsers = async (page, token) => {
    const users = await getUsers(token)
    setUsers(users.data)
  }
  useEffect(() => {
    getSetUsers(0, authToken)
  }, [authToken, reload])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleEditNew = (id, email) => {
    setEid(id)
    setOpenNew(true)
  }

  const handleBlock = async (id) => {
    try {
      await blockUser(authToken, id)
      setReload(reload + 1)
    } catch (error) {
      if (error.response.status === 401) {
        handleLogout(history)
      }
    }
  }

  const handleUnblock = async (id) => {
    try {
      await unblockUser(authToken, id)
      setReload(reload + 1)
    } catch (error) {
      if (error.response.status === 401) {
        handleLogout(history)
      }
    }
  }
  const handleDelete = async (id) => {
    try {
      await deleteUser(authToken, id)
      setDeleteOpenNew(false)
      setDeleteEmail('')
      setDid(null)
      setReload(reload + 1)
    } catch (error) {
      if (error.response.status === 401) {
        handleLogout(history)
      }
    }
  }

  const handleDeleteModalNew = async (id, email) => {
    setDid(id)
    setDeleteEmail(email)
    setDeleteOpenNew(true)
  }

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.id} className={classes.row}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align} padding='default'>
                          {(column.id === 'admin' ? (value ? 'Si' : 'No') : value)}
                          {(column.id === 'blocked' ? (value ? 'Si' : 'No') : null)}
                          {(column.id === 'actions') && (
                            <div>
                              <Tooltip title='Editar Usuario'>
                                <IconButton onClick={() => handleEditNew(row.id, row.email)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='Bloquear o desbloquear Usuario'>
                                {!row.blocked ? <IconButton onClick={() => handleBlock(row.id)}>
                                  <BlockIcon />
                                </IconButton>
                                  : <IconButton onClick={() => handleUnblock(row.id)}>
                                    <LockOpenIcon />
                                  </IconButton>}
                              </Tooltip>
                              <Tooltip title='Eliminar Usuario'>
                                <IconButton onClick={() => handleDeleteModalNew(row.id, row.email)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>)}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <NewEditUser open={openNew} setOpen={setOpenNew} id={eid} getUsers={getSetUsers} />
      <NewConfirmation open={deleteOpenNew} setOpen={setDeleteOpenNew} handleDelete={handleDelete} email={deleteEmail} id={did} />
    </div>
  )
}
