import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SettingsIcon from '@material-ui/icons/Settings'
import Profile from '../../Profile'
import Notifications from '../../Notifications'
import { useTranslation } from 'react-i18next'
function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component='span' variant='body2'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  verticaltabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '200px',
    paddingTop: '10px',
    textAlign: 'left',
    '& .MuiTabs-indicator': {
      backgroundColor: '#f5b63e'
    }
  },
  panel: {
    width: '100%'
  },
  tab: {
  }

}))

export default function VerticalTabs (props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { history } = props
  const [t, i18n] = useTranslation("global")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.verticaltabs}
      >

        <Tab
          className={classes.tab}
          label={<div><NotificationsIcon style={{ verticalAlign: 'middle' }} />  {t("profile.notifications")}</div>}
          {...a11yProps(0)}
        />
        <Tab
          className={classes.tab}
          label={<div><SettingsIcon style={{ verticalAlign: 'middle' }} />  {t("profile.configuration")} </div>}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel className={classes.panel} value={value} index={0}>
        <Notifications history={history} />
      </TabPanel>
      <TabPanel className={classes.panel} value={value} index={1}>
        <Profile />
      </TabPanel>
    </div>
  )
}
