import React from 'react'
import { makeStyles } from '@material-ui/styles'
import OP3 from '../../assets/gifs/opcion4.gif'
import { Button } from '../../components'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: '"Open Sans"',
    height: '100vh',
    position: 'relative',
    '& h1': {
      fontSize: '3.8em',
      margin: 0,
      lineHeight: '1.2em'
    }
  },
  padding: {
    padding: '3%'
  },
  centerDiv: {
    paddingTop: '2%',
    width: '50%',
    margin: 'auto',
    alignItems: 'center'
  },
  title: {
    width: '70%',
    margin: 'auto',
    fontFamily: 'Indie Flower'
  },
  img: {
    maxWidth: '300px',
    maxHeight: '400px'
  }
}))

const NotFound = (props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div className={classes.centerDiv}>
        <div className={classes.padding}><h1>Oops! Esta página no existe!</h1></div>
        <div className={classes.padding}><img className={classes.img} src={OP3} alt='prop' /> </div>
      </div>
      <Button
        onClick={() => history.push('/')}
        label='Volver'
        type='secondary'
        size='medium'
        data-testid='signin-btn'
      />
    </div>
  )
}

export default NotFound
