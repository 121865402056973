import React, { useState } from 'react'
import { Button, TextField } from '../../../../../../components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  row: {
    padding: '15px'
  },
  buttons: {
    float: 'right'
  }
}))
export default function EditCard (props) {
  const {
    title,
    cancelEditMode,
    description,
    handleSubmit
  } = props
  const classes2 = useStyles()
  const [newTitle, setNewTitle] = useState(title)
  const [newDescription, setNewDescription] = useState(description)

  return (
    <div>
      <div className={classes2.row}>
        <TextField
          label='Editar título'
          onChange={(e) => setNewTitle(e.target.value)}
          value={newTitle}
        />
      </div>
      <div className={classes2.row}>
        <TextField
          label='Agregar respuesta'
          onChange={(e) => setNewDescription(e.target.value)}
          value={newDescription}
        />
      </div>
      <div className={`${classes2.row} ${classes2.buttons}`}>
        <Button
          onClick={() => cancelEditMode()}
          label='Cancelar'
          type='secondary'
          size='small'
        />
        <Button
          onClick={() => handleSubmit(newTitle, newDescription)}
          label='Guardar'
          type='primary'
          size='small'
        />
      </div>
    </div>

  )
}
