import { createStore } from 'redux'
import { rootReducer } from './reducers'
import SecureStorage from 'secure-web-storage'

var CryptoJS = require('crypto-js')

var S_KEY = 'kl9gZEwRFbnceTPAQy4M3wRbPxoD15zf'

var secureStorage = new SecureStorage(localStorage, {
  hash: function hash (key) {
    key = CryptoJS.SHA256(key, S_KEY)
    return key.toString()
  },
  encrypt: function encrypt (data) {
    data = CryptoJS.AES.encrypt(data, S_KEY)
    data = data.toString()
    return data
  },
  decrypt: function decrypt (data) {
    data = CryptoJS.AES.decrypt(data, S_KEY)
    data = data.toString(CryptoJS.enc.Utf8)
    return data
  }
})

function StoreLocalStorage (state) {
  try {
    // console.log(state);
    const serializedState = JSON.stringify(state)
    // console.log("Aquí todo bien");
    secureStorage.setItem('state', serializedState)
  } catch (e) {
    // console.log("Hay un error D:");
    console.log(e)
  }
}
function LoadLocalStorage () {
  try {
    const serializedState = secureStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}
const persistedState = LoadLocalStorage()

const store = createStore(
  rootReducer, persistedState
)

store.subscribe(() => StoreLocalStorage(store.getState()))

export default store
