import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import withWidth from '@material-ui/core/withWidth'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import DocTreeView from './TreeView'
import CircularProgress from '../../../components/CircularProgress'
import { Button } from '@material-ui/core'
import getCases from '../../../utils/cases/Cases'

const drawerWidth = 270

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  drawer: {
    textAlign: 'left',
    flexShrink: 0,
    height: '100vh',
    position: 'sticky'
  },
  drawerPaper: {
    marginLeft: '1%',
    marginRight: '1%',
    marginTop: '5%',
    width: drawerWidth,
    height: '100%'
  },
  drawerContainer: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  btn: {
    marginTop: '5%',
    textAlign: 'left',
    flexGrow: 1
  },
  fixBottom: {
    position: 'fixed',
    width: '100%',
    bottom: '0px',
    right: '0px',
    zIndex: 1
  },
  mobileVersion: {
    maxWidth: '60%'
  }
}))

function ClippedDrawer (props) {
  const classes = useStyles()
  const { width } = props
  const [side, setSide] = useState(false)
  const [data, setData] = useState([])
  const [open, setOpen] = useState('')
  const [loading, setLoading] = useState(true)
  const { useCase, history } = props

  const handleClose = () => {
    setSide(!side)
  }

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const result = await getCases()
      setData(result.data)
      if (!useCase) {
        setOpen(result.data[0].id.toString())
      } else {
        setOpen(useCase)
      }
      setLoading(false)
      // ...
    }
    fetchData()
  }, [useCase])
  return (
    <div>
      {!loading && (
        <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant={width === 'lg' || width === 'md' || width === 'xl' ? 'permanent' : 'temporary'}
            open={side}
            onClose={handleClose}
            classes={{
              paper: width === 'lg' || width === 'md' || width === 'xl' ? classes.drawerPaper : classes.mobileVersion
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <div style={{ minHeight: open ? 1000 : 0}}>
                <DocTreeView cases={data} history={history} useCase={open} />
              </div>
            </div>
          </Drawer>
          {width !== 'lg' && width !== 'md' && width !== 'xl' && (
            <Button variant='contained' aria-label='side' className={classes.fixBottom} onClick={() => setSide(true)} startIcon={<KeyboardArrowRightIcon />}> </Button>
          )}
        </div>
      )}
      {loading && (
        <CircularProgress />
      )}
    </div>
  )
}

export default withWidth()(ClippedDrawer)
