import React from 'react'
import { Grid } from '@material-ui/core'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CodeIcon from '@material-ui/icons/Code'
import { Button } from '../../../../components'
import FastForwardIcon from '@material-ui/icons/FastForward'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const LearnContainer = (props) => {
  const { classes } = props
  const auth = useSelector(state => state.auth)
  const history = useHistory()
  const [t, i18n] = useTranslation("global")

  return (
    <div className={classes.subContainer}>
      <h2>{t("learn-container.lets-start")}</h2>
      <Grid
        container spacing={2}
        direction='row'
        justify='space-evenly'
        className={classes.productGrid}
      >
        <Grid item xs={12} lg={3} md={3}>
          <AssignmentIcon className={classes.icon} />
          <h3>{t("learn-container.register")}</h3>
          <h5>{t("learn-container.register-info")}</h5>
        </Grid>
        <Grid item xs={12} lg={1} md={1}>
          <FastForwardIcon className={classes.iconL} />
        </Grid>
        <Grid item xs={12} lg={3} md={3}>
          <ImportContactsIcon className={classes.icon} />
          <h3>{t("learn-container.learn")}</h3>
          <h5>{t("learn-container.learn-info")}</h5>
        </Grid>
        <Grid item xs={12} lg={1} md={1}>
          <FastForwardIcon className={classes.iconL} />
        </Grid>
        <Grid item xs={12} lg={3} md={3}>
          <CodeIcon className={classes.icon} />
          <h3>{t("learn-container.develop")}</h3>
          <h5>{t("learn-container.develop-info")}</h5>
        </Grid>
      </Grid>
      <div className={`${classes.buttonLine} ${classes.learnButtonLine}`}>
        {!auth.loggingIn
          ? (
            <Button
              onClick={() => history.push('/sign-in')}
              label={t("title-landing.start-button")}
              type='secondary'
              size='big'
              data-testid='signin-btn'
            />)
          : (
            <Button
              onClick={() => history.push('/cases')}
              label={t("title-landing.user-manual-button")}
              type='secondary'
              size='big'
              data-testid='signin-btn'
            />)}
        <Button
          onClick={() => history.push('/docs')}
          label={t("title-landing.documentation-button")}
          type='primary'
          size='big'
          data-testid='signin-btn'
        />
      </div>
    </div>
  )
}
export default LearnContainer
