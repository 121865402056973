import React, { useState, useEffect } from 'react'
import CaseCard from './components/CaseCard'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import MenuCase from './components/MenuCase'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto'
  },
  container: {
    marginTop: '2%',
    height: '100%'
  }
}))

const Cases = (props) => {
  const [useCase, setUseCase] = useState(null)
  const classes = useStyles()
  const { id } = useParams()
  const { history } = props
  const handleUserCase = (idUseCase) => {
    setUseCase(idUseCase)
  }
  useEffect(() => {
    async function fetchData () {
      // You can await here
      setUseCase(id)
      // ...
    }
    fetchData()
  }, [id])

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          <div className={classes.root}>
            <MenuCase setUserCase={handleUserCase} useCase={id} history={history} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <CaseCard id={useCase} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Cases
