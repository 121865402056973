import React, { useState, useEffect } from 'react'
import getCategoryArticles from '../../../utils/community/CategoryArticles'
import moment from 'moment'
import { Button, CircularProgress } from '../../../components'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'

const Announcement = (props) => {
  const [article, setArticle] = useState([])
  const [loading, setLoading] = useState(true)
  const sanitizer = DOMPurify.sanitize
  const { history } = props
  const [t, i18n] = useTranslation("global")

  const openArticle = () => {
    history.push('/community/' + article.id)
  }
  useEffect(() => {
    async function fetchData () {
      // You can await her
      try {
        const result = await getCategoryArticles(3, 1)
        const articlesSorted = result.data.data.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at)
        })
        setArticle(articlesSorted[0])
        setLoading(false)
      } catch (error) {
        if (error.response.status === 404) {
          setLoading(false)
          // Manejar caso cuando no hay articulos
        }
      }
    }
    fetchData()
  }, [])
  return (
    <div>
      {!loading && article.title && (
        <div>
          <p>{t("community.last-entry")} - {moment(article.created_at).format('DD/MM/YYYY')} </p>
          <h3> {article.title} </h3>
          <p> <span dangerouslySetInnerHTML={{ __html: sanitizer(article.description) }} /> </p>
          <Button label={t("community.more")} type='secondary' size='medium' onClick={() => openArticle()} />
        </div>
      )}
      {loading && (
        <CircularProgress />
      )}
      {!loading && !article.title && (
        <div>
          <h3>{t("community.no-announcements")}</h3>
        </div>
      )}
    </div>
  )
}

export default Announcement
