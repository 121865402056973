import React, { useState } from 'react'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CreateIcon from '@material-ui/icons/Create'

import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import clsx from 'clsx'

export default function EditCard (props) {
  const {
    classes,
    title,
    description,
    admin,
    handleDelete,
    cancelEditMode,
    cardId
  } = props
  const [expandedLast, setExpandedLast] = useState(false)
  const handleExpandLastClick = () => {
    setExpandedLast(!expandedLast)
  }
  return (
    <div>
      <div className={classes.logoTitle}>
        <h4>{title}</h4>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expandedLast
          })}
          onClick={handleExpandLastClick}
          aria-expanded={expandedLast}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </IconButton>
        {admin && <IconButton
          onClick={cancelEditMode}
        >
          <CreateIcon />
        </IconButton>}
        {admin && <IconButton
          onClick={() => handleDelete(cardId)}
        >
          <ClearIcon />
        </IconButton>}
      </div>
      <Collapse in={expandedLast} timeout='auto' unmountOnExit>
        <div className={classes.description}>
          <p>{description}</p>
        </div>
      </Collapse>
    </div>

  )
}
