import React, { useState } from 'react'
import { Button, TextField } from '../../components'
import { makeStyles } from '@material-ui/styles'
import ReCAPTCHA from 'react-google-recaptcha'
import { useParams } from 'react-router-dom'
import recoverPassword from '../../utils/fetch/recover'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '3%',
    alignItems: 'center'
  },
  title: {
    paddingTop: '1px',
    fontSize: '1.9em',
    color: '#3b4754'
  },
  card: {
    margin: 'auto',
    maxWidth: '32.5%',
    backgroundColor: '#FFF',
    borderRadius: '20px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  text: {
    paddingTop: '20px'
  },
  warningText: {
    color: 'red'
  },
  container: {
    padding: '8%',
    paddingTop: '0'
  },
  captcha: {
    textAlign: 'center',
    display: 'inline-block'
  }
}))

const Restore = props => {
  const classes = useStyles()
  const [captcha, setCaptcha] = useState(false)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(true)
  const [errorText, setErrorText] = useState('')
  const [loaded, setLoaded] = useState(false)
  const { recoverToken } = useParams()

  const handleSubmit = async (e) => {
    setLoading(true)
    await recoverPassword(recoverToken, password)
      .then((res) => {
        setLoaded(true)
        setLoading(false)
      })
      .catch(() => {
        setError(true)
        setErrorText('Error link no válido, revisa tu correo')
        setPassword('')
        setLoading(false)
      })
  }

  function onChange (value) {
    setCaptcha(!captcha)
  }
  const errorButton = () => {
    setErrorText('No se ha rellenado correctamente el formulario')
  }
  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.title}>
          <h3>Reestablecer contraseña</h3>
        </div>
        <div className={classes.container}>
          {!loaded && (
            <>
              <div className={classes.text}>
                <TextField
                  key='password'
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Contraseña nueva'
                  label='Contraseña nueva'
                  value={password}
                  name='password'
                  type='password'
                />
              </div>
              <div className={classes.text}>
                <div className={classes.captcha}>
                  <ReCAPTCHA
                    sitekey='6LcyI70ZAAAAAEdifcB_pF9eim5E41m11p__B4BX'
                    onChange={onChange}
                  />
                  {error && <p className={classes.warningText}>{errorText}</p>}
                </div>
              </div>
              <div className={classes.text}>
                {(!loading && captcha && password !== '') && <Button size='signin' label='Reestablecer' type='secondary' onClick={() => { handleSubmit() }} />}
                {(!loading && !captcha && password !== '') && <Button size='signin' label='Reestablecer' type='disabled' onClick={() => errorButton()} />}
                {(!loading && password === '') && <Button size='signin' label='Reestablecer' type='disabled' onClick={() => errorButton()} />}
              </div>
              <div className={classes.text}>
                <p>Tienes cuenta? <a href='/sign-in'>Inicia Sesión!</a></p>
              </div>
            </>
          )}
          {loaded && (
            <div className={classes.text}>
              Se ha reestablecido tu contraseña, por favor inicia sesión.
              <a href='/sign-in'>Inicia Sesión!</a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Restore
