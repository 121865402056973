import axios from 'axios'
import { URL } from '../url'

export default async function createUser (obj, productId, token) {
  const data = {
    docs: obj
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  try {
    const response = await axios.post(URL + '/docs/' + productId.toString(), data, headers)
    return response
  } catch (err) {
    return err.response
  }
}
