import React from 'react'
import { VerticalTabs, HorizontalTabs } from './tabs'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  vertical: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  horizontal: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))
const ProfilePanel = (props) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className='container'>
      <VerticalTabs className={classes.vertical} history={history} />
      <HorizontalTabs className={classes.horizontal} history={history} />
    </div>
  )
}

export default ProfilePanel
