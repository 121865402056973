import React, { useEffect, useState } from 'react'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import getCase from '../../../utils/cases/Case'
import CircularProgress from '../../../components/CircularProgress'
import { useParams } from 'react-router-dom'
import '../styles/card.scss'
import Highlight from 'react-highlight'
import LikePage from './LikePage'

const useStyles = makeStyles(theme => ({
  text: {
    paddingTop: '20px',
    textAlign: 'justify'
  },
  card: {
    padding: '20px',
    marginRight: '1%',
    backgroundColor: '#FFF',
    width: '100%'
  }
}))

const CaseCard = (props) => {
  const [title, setTitle] = useState('Elige un caso')
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState('<p> <strong> Usa la barra lateral </strong></p>')
  const language = navigator.languages[0].split("-")[0] === "es" ? "es" : "en";
  const classes = useStyles()
  const { id } = useParams()
  const mainCaseId = language === "es" ? "2004844612" : "2126905407" 

  useEffect(() => {
    async function fetchData () {
      // You can await here
      setLoading(true)
      if (id) {
        await getCase(id)
          .then((result) => {
            setTitle(result.data.title)
            setBody(result.data.body)
          })
          .catch((error) => {
            if (error.response.status === 404 || error.response.status === 500) {
              setTitle('Esta página no existe!')
              setBody('')
            }
          })
      } else {
        await getCase(mainCaseId)
          .then((result) => {
            setTitle(result.data.title)
            setBody(result.data.body)
          })
          .catch((error) => {
            if (error.response.status === 404 || error.response.status === 500) {
              setTitle('Esta página no existe!')
              setBody('')
            }
          })
      }
      setLoading(false)
    }
    fetchData()
  }, [id])
  return (
    <div className='confluence'>
      <link type='text/css' rel='stylesheet' href='//confluence-v1.prod.atl-paas.net/master/aui-reset.css' data-wrm-external data-wrm-key='com.atlassian.auiplugin:aui-reset' data-wrm-batch-type='resource' media='all' />
      <link type='text/css' rel='stylesheet' href='//confluence-v1.prod.atl-paas.net/master/aui-page-typography.css' data-wrm-external data-wrm-key='com.atlassian.auiplugin:aui-page-typography' data-wrm-batch-type='resource' media='all' />
      <link type='text/css' rel='stylesheet' href='//d2r19am4nu5qu1.cloudfront.net/beetrack.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-835071594/h/210b8ddbcac0b05e5476c72c35ac5ade/_/download/contextbatch/css/_super/batch.css?externals=__local-default__&amp;relative-url=true' data-wrm-key='_super' data-wrm-batch-type='context' media='all' />
      <link type='text/css' rel='stylesheet' href='//d2r19am4nu5qu1.cloudfront.net/beetrack.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-835071594/h/0a755ea35227a7421e0ea53ab68cc31b/_/download/contextbatch/css/plugin.viewsource,-_super/batch.css?externals=__local-default__&amp;relative-url=true' data-wrm-key='plugin.viewsource,-_super' data-wrm-batch-type='context' media='all' />
      <link type='text/css' rel='stylesheet' href='//d2r19am4nu5qu1.cloudfront.net/beetrack.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-835071594/h/3f463fdc98ced4954b662e8251e1395d/_/download/contextbatch/css/page,-_super/batch.css?build-number=6452&amp;externals=__local-default__&amp;relative-url=true' data-wrm-key='page,-_super' data-wrm-batch-type='context' media='all' />
      <link type='text/css' rel='stylesheet' href='//d2r19am4nu5qu1.cloudfront.net/beetrack.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-835071594/h/3f463fdc98ced4954b662e8251e1395d/_/download/contextbatch/css/page,-_super/batch.css?build-number=6452&amp;externals=__local-default__&amp;media=print&amp;relative-url=true' media='print' data-wrm-key='page,-_super' data-wrm-batch-type='context' />
      <link type='text/css' rel='stylesheet' href='//d2r19am4nu5qu1.cloudfront.net/beetrack.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-835071594/h/d4e4e19c4669223aa75450869203638e/_/download/contextbatch/css/editor-content,-_super/batch.css?confluence.table.resizable=true&amp;externals=__local-default__&amp;relative-url=true' data-wrm-key='editor-content,-_super' data-wrm-batch-type='context' media='all' />
      <link rel='stylesheet' href='https://highlightjs.org/static/demo/styles/a11y-light.css' />
      <div className={classes.card}>
        {!loading && (
          <div>
            <h3>{title}</h3>
            <div className={classes.text}>
              <Divider />
            </div>
            <div className={classes.text}>
              <Highlight innerHTML>
                {body}
              </Highlight>
            </div>
            {id ? <LikePage /> : null}
          </div>
        )}
        {loading && (
          <CircularProgress />
        )}
      </div>
    </div>
  )
}

export default CaseCard
