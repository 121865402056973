import React from 'react'
import {
  Route
} from 'react-router-dom'
import { useSelector } from 'react-redux'

const UserRoute = (props) => {
  const auth = useSelector(state => state.auth)
  const {
    path,
    adminProtected,
    component,
    notAccess,
    signin
  } = props
  if (adminProtected && auth.currentUserRole) return <Route exact path={path} component={component} />
  if (adminProtected && !auth.currentUserRole) return <Route exact path={path} component={notAccess} />
  if (auth.loggingIn) return <Route exact path={path} component={component} />
  return <Route exact path={path} component={signin} />
}

export default UserRoute
