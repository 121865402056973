import axios from 'axios'
import { URL } from '../url'

export default async function editArticle (token, title, content, id) {
  const data = {
    title: title,
    description: content
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.patch(URL + '/articles/' + id.toString(), data, headers)
  return response
}
