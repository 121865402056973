import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ButtonMUI from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '../../../../components'
// import SearchIcon from '@material-ui/icons/Search'
import SecurityIcon from '@material-ui/icons/Security'
import Badge from '@material-ui/core/Badge'
import getNotifications from '../../../../utils/fetch/notifications'
import Beetrack from '../../../../assets/images/BEE HN.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    fontFamily: '"Open Sans"',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    padding: '0.5%',
    paddingLeft: '1.5%',
    paddingRight: '1.5%'
  },
  menuOptions: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    color: 'black',
    '& a': {
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'none'
    }
  },
  menuButton: {
    color: 'black',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  iconButton: {
    marginLeft: '20px'
  },
  icon: {
    fontSize: '2.2rem'
  },
  title: {
    flexGrow: 1,
    textAlign: 'right',
    paddingRight: '1%',
    verticalAlign: 'middle'
  },
  btn: {
    fontSize: '16px',
    padding: '16px',
    fontWeight: 'bold',
    fontFamily: '"Open Sans"',
    textTransform: 'none'

  },
  btnSelected: {
    borderRadius: 0,
    borderBottom: '2px solid #f5b63e',
    color: '#f5b63e'
  },
  btnNormal: {
    color: '#3b4754'
  },
  productLogo: {
    width: '200px',
    cursor: 'pointer'
  }
}))

const DrawerBar = (props) => {
  const {
    history,
    auth,
    handleLogout,
    handleLogin,
    actualPage,
    handleDrawerToggle
  } = props
  const classes = useStyles()
  const [lengthNotifications, setLengthNotifications] = useState(0)
  const [t, i18n] = useTranslation("global")

  useEffect(() => {
    async function fetchData () {
      // You can await here
      if (auth.currentToken) {
        try {
          const notifications = await getNotifications(auth.currentToken)
          setLengthNotifications(notifications.data.length)
        } catch (error) {
          if (error.response) {
            if (error.response.status) {
              handleLogout(history)
            }
          }
        }
      }
      // ...
    }
    fetchData()
  }, [actualPage, auth.currentToken, history, handleLogout])
  return (
    <AppBar position='fixed' className={classes.appBar}>
      <link href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap' rel='stylesheet' />
      <Toolbar>
        {/* <IconButton edge='start' color='inherit' aria-label='menu' onClick={() => history.push('/')}> */}
        <img alt='landing-img' onClick={() => history.push('/')} className={classes.productLogo} src={Beetrack} />
        {/* </IconButton> */}
        <Typography variant='h6' className={classes.title}>
          <div className={classes.menuOptions}>
            <a href='/services' className={classes.noDeco}>
              <ButtonMUI
                data-testid='services-btn'
                className={`${classes.btn} ${actualPage !== 'services' ? classes.btnNormal : classes.btnSelected}`}
              > {t("menu.products")}
              </ButtonMUI>
            </a>
            <a href='/cases' className={classes.noDeco}>
              <ButtonMUI
                data-testid='cases-btn'
                className={`${classes.btn} ${actualPage !== 'cases' ? classes.btnNormal : classes.btnSelected}`}
              > {t("menu.user-manual")}
              </ButtonMUI>
            </a>
            <a href='/docs' className={classes.noDeco}>
              <ButtonMUI
                data-testid='docs-btn'
                className={`${classes.btn} ${actualPage !== 'docs' ? classes.btnNormal : classes.btnSelected}`}
              > {t("menu.api-documentation")}
              </ButtonMUI>
            </a>
            {auth.loggingIn &&
              <a href='/dashboard' className={classes.noDeco}>
                <ButtonMUI
                  data-testid='admin-btn'
                  className={`${classes.btn} ${actualPage !== 'dashboard' ? classes.btnNormal : classes.btnSelected}`}
                > {t("menu.dashboard")}
                </ButtonMUI>
              </a>}
            <a href='/community' className={classes.noDeco}>
              <ButtonMUI
                data-testid='community-btn'
                className={`${classes.btn} ${actualPage !== 'community' ? classes.btnNormal : classes.btnSelected}`}
              > {t("menu.community")}
              </ButtonMUI>
            </a>
            <a href='/faq' className={classes.noDeco}>
              <ButtonMUI
                data-testid='faq-btn'
                className={`${classes.btn} ${actualPage !== 'faq' ? classes.btnNormal : classes.btnSelected}`}
              > FAQ
              </ButtonMUI>
            </a>
          </div>
        </Typography>
        <Typography variant='h6' className={classes.title}>
          <div className={classes.menuOptions}>
            {auth.loggingIn &&
              <div>
                {auth.currentUserRole &&
                  <IconButton
                    className={actualPage !== 'adminpanel' ? classes.icon : classes.btnSelected}
                    onClick={() => history.push('/adminpanel')}
                    color='inherit'
                  >
                    <SecurityIcon />
                  </IconButton>}
                <IconButton
                  className={actualPage !== 'profile' ? classes.icon : classes.btnSelected}
                  onClick={() => history.push('/profile')}
                  color='inherit'
                >
                  <Badge badgeContent={lengthNotifications} color='error'>
                    <AccountCircle />
                  </Badge>
                </IconButton>
                {/* <IconButton
                  color='inherit'
                  className={classes.icon}
                >
                  <SearchIcon />
                </IconButton> */}
                <IconButton
                  className={classes.icon}
                  onClick={handleLogout}
                  color='inherit'
                >
                  <ExitToAppIcon />
                </IconButton>
              </div>}
            {!auth.loggingIn &&
              <div>
                <Button label={t("menu.login")} type='primary' size='small' data-testid='signin-btn' onClick={handleLogin} />
                {/* <IconButton
                  color='inherit'
                  className={classes.iconButton}
                >
                  <SearchIcon className={classes.icon} />
                </IconButton> */}
              </div>}
          </div>
        </Typography>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon className={classes.icon} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
export default DrawerBar
