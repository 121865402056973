import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PopoverMUI from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import ButtonFree from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import { TextField, Button } from '../../../../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto'
  },
  expand: {
    transform: 'rotate(270deg)',
    marginLeft: '10%'
  },
  expandOpen: {
    transform: 'rotate(90deg)'
  },
  webhook: {
    padding: 5,
    textTransform: 'lowercase'
  },
  popOver: {
    padding: '15px'
  },
  input: {
    height: '20px'
  },
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 120,
    height: '36px'
  },
  select: {
    height: '36px',
    '&:before': {
      // normal
      borderBottom: '1px solid orange'
    },
    '&:after': {
      // focused
      borderBottom: '3px solid green'
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: '2px solid purple'
    }
  }
}))

export default function Popover (props) {
  const classes = useStyles()
  const {
    anchorEl,
    handleInputChangeTI,
    handleClose,
    setTimeF,
    valueF,
    final
  } = props
  const [age, setAge] = useState(1)
  const handleChange = (event) => {
    setAge(event.target.value)
  }
  const confirmButon = () => {
    setTimeF(valueF * age)
    handleClose()
  }
  const quickButton = (value) => {
    setTimeF(value)
    handleClose()
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <PopoverMUI
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <div className={classes.popOver}>
        <Grid
          container
        >
          <Grid item lg={4}>
            <TextField
              label='Tiempo'
              placeholder='minutos'
              onChange={handleInputChangeTI}
              value={valueF}
              pop
            />
          </Grid>
          <Grid item lg={4}>
            <div>
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>Medida</InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={age}
                  onChange={handleChange}
                  label='Tiempo'
                  className={classes.select}
                >
                  <MenuItem value={1}>Minutos</MenuItem>
                  <MenuItem value={60}>Horas</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item lg={3}>
            <Button onClick={confirmButon} label='Confirmar' type='secondary' size='product' />
          </Grid>
        </Grid>
        <p>Selección rápida</p>
        <Grid
          container
        >
          {final &&
            <Grid item lg={4}>
              <ButtonFree onClick={() => quickButton(45)}>
                {'~ 45 Minutos'}
              </ButtonFree>
            </Grid>}
          <Grid item lg={4}>
            <ButtonFree name={30} onClick={() => quickButton(30)}>
              {'~ 30 Minutos'}
            </ButtonFree>
          </Grid>
          <Grid item lg={4}>
            <ButtonFree name={15} onClick={() => quickButton(15)}>
              {'~ 15 Minutos'}
            </ButtonFree>
          </Grid>
          {!final &&
            <Grid item lg={4}>
              <ButtonFree onClick={() => quickButton(0)}>
                {'Ahora'}
              </ButtonFree>
            </Grid>}
        </Grid>
      </div>
    </PopoverMUI>
  )
}
