import axios from 'axios'
import { URL } from '../url'

export default async function editUser (token, id, admin) {
  const data = {
    admin: admin
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.patch(URL + '/users/' + id.toString(), data, headers)
  return response
}
