import axios from 'axios'
import { URL } from '../url'

export default async function forgotPassword (email) {
  const data = {
    email: email
  }

  const response = await axios.post(URL + '/forgot_password', data)
  return response
}
