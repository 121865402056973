import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { amber } from '@material-ui/core/colors'
import getNotifications from '../../utils/fetch/notifications'
import { CircularProgress } from '../../components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ForumIcon from '@material-ui/icons/Forum'
import moment from 'moment'
import patchNotifications from '../../utils/fetch/watchNotifications'
import handleLogout from '../../utils/connect/logout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%'

  },
  captcha: {
    textAlign: 'center',
    display: 'inline-block'
  },
  text: {
    padding: '5%'
  },
  warningText: {
    color: 'red'
  },
  amber: {
    color: 'black',
    backgroundColor: amber[600],
    width: '5rem',
    height: '5rem',
    margin: 'auto'
  },
  container: {
    marginTop: '30px'
  },
  icon: {
    alignItems: 'center',
    display: 'flex'
  }
}))

const Notifications = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const { history } = props
  const [t, i18n] = useTranslation("global")
  
  const viewNotification = (article) => {
    history.push('/community/' + article.toString())
  }
  useEffect(() => {
    async function fetchData () {
      try {
        const result = await getNotifications(auth.currentToken)
        setNotifications(result.data)
        setLoading(false)
      } catch (error) {
        if (error.response.status) {
          handleLogout(history)
        }
      }
      await patchNotifications(auth.currentToken)
    }
    fetchData()
  }, [auth.currentToken, history])
  return (
    <div className={classes.root}>
      {!loading && (
        <div>
          {notifications.length === 0 && (
            <h5> {t("profile.no-news")} </h5>
          )}
          {notifications.length > 0 && (
            <List className={classes.root}>
              {notifications.map((noti, k) =>
                <ListItem button key={k} onClick={() => viewNotification(noti.link)}>
                  <ListItemAvatar>
                    <ForumIcon />
                  </ListItemAvatar>
                  <ListItemText primary={<div> <p>{moment(noti.created_at).format('DD/MM/YYYY')}</p> <h4>{noti.message}</h4> </div>} secondary={t("profile.see-more")} />
                </ListItem>)}
            </List>
          )}
        </div>
      )}
      {loading && (
        <CircularProgress />
      )}
    </div>
  )
}

export default Notifications
