import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import getWebooks from '../../utils/fetch/getWebhooks'
import { ApikeysPanel, DashboardPanel, HeaderPanel } from './components'
import { sumObjectsByKey, getTotalLogs, getTotalStats, generateBase, createList } from './helpers'
import { CircularProgress } from '../../components'
import handleLogout from '../../utils/connect/logout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2%',
    fontFamily: '"Open Sans'
  },
  line: {
    paddingTop: '2%'
  },
  waitDiv: {
    padding: '12%'
  },
  border: {
    borderRadius: 4,
    border: '0.1px solid rgba(133, 133, 133,0.5)'
  }
}))

const Dashboard = (props) => {
  const [apikeysPanel, setApikeysPanel] = useState(2)
  // const [filtersPanel, setFiltersPanel] = useState(0)
  const classes = useStyles()
  const auth = useSelector(state => state.auth)
  const [keys, setKeys] = useState([])
  const [keysOpen, setKeysOpen] = useState([])
  const [selectWebhooks, setSelectWebhooks] = useState([])
  const [loadingKeys, setLoadingKeys] = useState(false)
  const [totalLogs, setTotalLogs] = useState({})
  const [logsData, setLogsData] = useState([])
  const [hitsData, setHitsData] = useState([])
  const [eventData, setEventData] = useState([])
  const [trucksData, setTrucksData] = useState([])
  const [resourceData, setResourceData] = useState([])
  const [processStateData, setProcessStateData] = useState([])
  const [whFormat, setWhFormat] = useState([])
  const [timeF, setTimeF] = useState(15)
  const [timeI, setTimeI] = useState(0)
  const { match: { params } } = props
  const product = params.name
  const [totalInfo, setTotalInfo] = useState({})
  const { history } = props
  const [t, i18n] = useTranslation("global")

  const fetchSetLogs = async (data, product) => {
    // Vamos a hacer una request de los ultimos logs
    // desde el time_i al time_f de los webhooks que
    // tiene el usuario
    try {
      setLogsData([])
      const totalLogsP = await getTotalLogs(auth.currentToken, timeI, timeF, data, product)
      setTotalLogs(totalLogsP)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return handleLogout(history)
        }
      }
    }
  }

  const fetchSetStats = async (data, product) => {
    // Vamos a hacer una request de los ultimos logs
    // procesados desde el time_i al time_f de los
    //  webhooks que tiene el usuario
    try {
      const { totalStatsP, whFormatP } = await getTotalStats(auth.currentToken, timeF, timeI, data, product)
      const base = generateBase(totalStatsP)
      setWhFormat(whFormatP)
      setHitsData(base.hitFinal)
      setEventData(base.eventFinal)
      setTrucksData(base.trucksFinal)
      setResourceData(base.resourceFinal)
      setProcessStateData(base.processStateFinal)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          handleLogout(history)
        }
      }
    }
  }

  const refreshData = async (data) => {
    setLoadingKeys(true)
    // Por default, dejamos los webhooks sleccionados vacío
    setSelectWebhooks([])
    var keys = []
    for (const key in data) {
      key && keys.push(false)
    }
    // Este array indica que webhooks están desplegados
    setKeysOpen(keys)
    // Este array indica los webhooks totales
    setKeys(data)
    // Asíncronamente iremos a buscar los logs y las stats
    await Promise.all([fetchSetLogs(data, product), fetchSetStats(data, product)])
    setLoadingKeys(false)
  }

  useEffect(() => {
    async function fetchData () {
      setLoadingKeys(true)
      try {
        var data
        // Vamos a buscar los webhooks
        const result = await getWebooks(auth.currentToken, product.toLowerCase())
        data = result.data
        if (!data.length) {
          alert('No tienes Apikeys registradas')
        }
        setTotalInfo(data)
        // Vamos a processar la info relacionada a los webhooks
        await refreshData(data)
        setLoadingKeys(false)
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            handleLogout(history)
          }
        }
      }
    }
    fetchData()
  }, [auth.currentToken, history, product])

  const handleApikeysPanel = () => {
    if (apikeysPanel === 1) return setApikeysPanel(2)
    setApikeysPanel(1)
  }
  // const handleFiltersPanel = () => {
  //   if (filtersPanel === 1) {
  //     closeAllCards()
  //     if (apikeysPanel === 3) return setApikeysPanel(2)
  //     return setFiltersPanel(2)
  //   }
  //   setFiltersPanel(1)
  // }
  const handleCloseApikey = () => {
    for (const card of keysOpen) {
      if (card === true) return
    }
    return setApikeysPanel(2)
  }
  const handleOpenApikey = () => {
    // setFiltersPanel(1)
    setApikeysPanel(3)
  }

  // const closeAllCards = () => {
  //   var open = []
  //   for (const i in keysOpen) {
  //     open[i] = false
  //   }
  //   setKeysOpen(open)
  // }
  const handleSelectWebhook = (wh) => {
    var whs = [...selectWebhooks]
    if (whs.includes(wh)) {
      const index = whs.indexOf(wh)
      if (index > -1) {
        whs.splice(index, 1)
      }
      selectData(wh, false)
    } else {
      whs.push((wh))
      selectData(wh, true)
    }
    setSelectWebhooks(whs)
  }
  const selectData = (wh, sum) => {
    var finalData = [...hitsData]
    var eventDataP = [...eventData]
    var trucksDataP = [...trucksData]
    var resourceDataP = [...resourceData]
    var processStateDataP = [...processStateData]
    const finalLog = createList(totalLogs, selectWebhooks, wh, sum)
    if (Object.keys(whFormat.hits).includes(wh)) {
      finalData = sumObjectsByKey(finalData, whFormat.hits[wh], sum)
      eventDataP = sumObjectsByKey(eventDataP, whFormat.event[wh], sum)
      trucksDataP = sumObjectsByKey(trucksDataP, whFormat.trucks[wh], sum)
      resourceDataP = sumObjectsByKey(resourceDataP, whFormat.resource[wh], sum)
      processStateDataP = sumObjectsByKey(processStateDataP, whFormat.process_state[wh], sum)
    }
    setLogsData(finalLog)
    setTrucksData(trucksDataP)
    setResourceData(resourceDataP)
    setProcessStateData(processStateDataP)
    setEventData(eventDataP)
    setHitsData(finalData)
  }

  return (
    <div className={classes.container}>
      {auth.loggingIn && (
        !loadingKeys && (
          <div>
            <div className={classes.line}>
              <HeaderPanel
                timeF={timeF}
                timeI={timeI}
                setTimeF={setTimeF}
                setTimeI={setTimeI}
                webhooks={totalInfo}
                keys={keys}
                product={product}
                refreshData={refreshData}
              />
            </div>
            <div className={classes.line}>
              <Grid container spacing={4} margin='0px'>
                <Grid item xs={12} lg={apikeysPanel}>
                  <div className={classes.border}>
                    <ApikeysPanel
                      handleOpenApikey={handleOpenApikey}
                      panel={apikeysPanel}
                      setPanel={handleApikeysPanel}
                      keys={keys}
                      keysOpen={keysOpen}
                      setKeysOpen={setKeysOpen}
                      handleCloseApikey={handleCloseApikey}
                      handleSelectWebhook={handleSelectWebhook}
                      selectWebhooks={selectWebhooks}
                      loadingKeys={loadingKeys}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={12} lg={filtersPanel}>
                  <FiltersPanel
                    panel={filtersPanel}
                    keys={dataFields}
                    setPanel={handleFiltersPanel}
                  />
                </Grid> */}
                <Grid item xs={12} lg={12 - apikeysPanel}>
                  <div className={classes.border}>
                    <DashboardPanel
                      product={product}
                      selectWebhooks={selectWebhooks}
                      logsData={logsData}
                      data={hitsData}
                      eventData={eventData}
                      trucksData={trucksData}
                      resourceData={resourceData}
                      processStateData={processStateData}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      )}
      {!auth.loggingIn && (
        <h1>Error 404, nothing in here!</h1>
      )}
      {loadingKeys && (
        <div className={classes.waitDiv}>
          <h1>{t("dashboard.loading")}</h1>
          <p>{t("dashboard.wait")}</p>
          <CircularProgress />
        </div>
      )}

    </div>
  )
}

export default Dashboard
