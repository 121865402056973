import axios from 'axios'
import { URL } from '../url'

export default async function getArticle (token, id) {
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.get(URL + '/articles/' + id.toString(), headers)
  return response
}
