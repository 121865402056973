import React, { useState, useEffect } from 'react'
import Modal from '@material-ui/core/Modal'
import ReCAPTCHA from 'react-google-recaptcha'
import { makeStyles } from '@material-ui/styles'
import { MenuItem } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import Button from '../../../../components/Button'
import TextField from '../../../../components/TextField'
import getApps from '../../../../utils/services/apps'
import FMSVG from '../../../../assets/images/H FM.svg'
import LMSVG from '../../../../assets/images/H LM.svg'
import ODSVG from '../../../../assets/images/H OD.svg'
import PPSVG from '../../../../assets/images/H PP.svg'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%'
  },
  textCenter: {
    textAlign: 'center'
  },
  textField: {
    margin: 'auto',
    padding: '2%',
    maxWidth: '75%',
    textAlign: 'center'
  },
  captcha: {
    padding: '2%',
    textAlign: 'center',
    display: 'inline-block'
  },
  productLogo: {
    width: '100px'
  },
  error: {
    color: 'red'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  logoTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    maxWidth: '43%',
    margin: 'auto'
  },
  modal: {
    maxHeight: '350px'
  },
  row: {
    padding: '25px'
  },
  alignRight: {
    float: 'right'
  },
  paper: {
    position: 'absolute',
    top: '20%',
    left: '31%',
    width: '38%',
    backgroundColor: '#ffffff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  title: {
    textAlign: 'center'
  }
}))
const ModalInput = (props) => {
  const classes = useStyles()
  const [type, setType] = useState('1')
  const [expandedLast, setExpandedLast] = useState(false)
  const [apps, setApps] = useState([])
  const {
    open,
    setOpen,
    comment,
    setComment,
    setNewkey,
    newKey,
    newKeySecret,
    setNewkeySecret,
    handleClickFunc
  } = props
  useEffect(() => {
    async function fetchData () {
      // You can await here
      const result = await getApps()
      const data = result.data
      data.sort(function (a, b) {
        return (-(b.id - a.id || a.name.localeCompare(b.name)))
      })
      setApps(data)
      // ...
    }
    fetchData()
  }, [])
  const handleExpandLastClick = () => {
    setExpandedLast(!expandedLast)
  }
  const handleType = (e) => {
    setType(e.target.id)
    setExpandedLast(!expandedLast)
  }
  return (
    <Modal
      key='scrollable-modal'
      open={open}
      onClose={() => setOpen(false)}
    >
      <div>
        <div className={classes.paper}>
          <div className={classes.textField}>
            <p>Seleccione su producto</p>
            <div className={classes.icon}>
              <div className={classes.logoTitle}>
                {type === '1' && <img alt='landing-img' className={classes.productLogo} src={LMSVG} />}
                {type === '2' && <img alt='landing-img' className={classes.productLogo} src={PPSVG} />}
                {type === '3' && <img alt='landing-img' className={classes.productLogo} src={FMSVG} />}
                {type === '4' && <img alt='landing-img' className={classes.productLogo} src={ODSVG} />}
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedLast
                  })}
                  onClick={handleExpandLastClick}
                  aria-expanded={expandedLast}
                  aria-label='show more'
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
              <Collapse in={expandedLast} timeout='auto' unmountOnExit>
                {apps.map((app, k) => {
                  return (
                    <MenuItem key={k} id={app.id} onClick={handleType}>{app.name}</MenuItem>
                  )
                }
                )}
              </Collapse>
            </div>
          </div>
          <div className={classes.textField}>
            <TextField
              placeholder='API Key'
              label='API Key'
              value={newKey}
              onChange={(e) => setNewkey(e.target.value)}
              name='newkey'
            />
          </div>
          {type === '2' &&
            <div className={classes.textField}>
              <TextField
                placeholder='API Secret'
                label='API Secret'
                value={newKeySecret}
                onChange={(e) => setNewkeySecret(e.target.value)}
                name='newkey'
              />
            </div>}
          <div className={classes.textField}>
            <TextField
              placeholder='Descripción'
              label='Breve descripción'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              name='comment'
            />
          </div>
          <div className={classes.textCenter}>
            <div className={classes.captcha}>
              <ReCAPTCHA
                sitekey='6LcyI70ZAAAAAEdifcB_pF9eim5E41m11p__B4BX'
                onChange={props.onChange}
              />
            </div>
            <div className={classes.error}>
              <p>{props.errorText}</p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.alignRight}>
              <Button
                onClick={() => setOpen(false)}
                label='Cancelar'
                type='primary'
                size='small'
              />
              <Button
                onClick={() => handleClickFunc(type)}
                label='Guardar'
                type='secondary'
                size='small'
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalInput
