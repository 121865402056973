import React from 'react'
import {
  Route,
  Switch
} from 'react-router-dom'
import {
  Home as HomeView,
  SignIn as SignInView,
  SignUp as SignUpView,
  Services as ServicesView,
  Faq as FaqsView,
  Docs as DocsView,
  Dashboard as DashboardView,
  Community as CommunityView,
  Cases as CasesView,
  Profile as ProfileView,
  Post as PostView,
  ShowPost as ShowPostView,
  AdminPanel as AdminPanelView,
  LastMileDoc as LastMileDocView,
  NotFound as NotFoundView,
  Categories as CategoriesView,
  SearchCategories as SearchCategoriesView,
  SearchAll as SearchAllView,
  Forgot as ForgotView,
  Restore as RestoreView,
  DashboardSelection as DashboardSelectionView
} from './views'
import { UserCheckRoute } from './components'

const Routes = (props) => {
  return (
    <Switch>
      <Route exact path='/' component={HomeView} />
      <Route exact path='/sign-in' component={SignInView} />
      <Route exact path='/sign-up' component={SignUpView} />
      <Route exact path='/forgot_password' component={ForgotView} />
      <Route exact path='/recover/:recoverToken' component={RestoreView} />
      <Route exact path='/services' component={ServicesView} />
      <Route exact path='/services/' component={ServicesView} />
      <Route exact path='/services/:id' component={ServicesView} />
      <Route exact path='/faq' component={FaqsView} />
      <Route exact path='/docs' component={DocsView} />
      <Route exact path='/docs/:name' component={LastMileDocView} />
      <UserCheckRoute exact path='/dashboard' component={DashboardSelectionView} signin={SignInView} />
      <UserCheckRoute exact path='/dashboard/:name' component={DashboardView} signin={SignInView} />
      <Route exact path='/community' component={CommunityView} />
      <UserCheckRoute adminProtected exact path='/adminpanel' component={AdminPanelView} notAccess={NotFoundView} />
      <Route exact path='/cases' component={CasesView} />
      <Route exact path='/community/new/:name/:categoryId' component={PostView} />
      <Route exact path='/community/:id' component={ShowPostView} />
      <Route exact path='/cases/:id' component={CasesView} />
      <UserCheckRoute exact path='/profile' component={ProfileView} signin={SignInView} />
      <Route exact path='/community/categories/:name/:id' component={CategoriesView} />
      <Route exact path='/community/search/:name/:id/:query' component={SearchCategoriesView} />
      <Route exact path='/community/search/:query' component={SearchAllView} />
      <Route exact path='/*' component={NotFoundView} />
    </Switch>
  )
}

export default Routes
