import React from 'react'
import { Grid } from '@material-ui/core'
import BG from '../../../../assets/images/develop.png'
import { Button } from '../../../../components'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const IntroContainer = (props) => {
  const { classes } = props
  const history = useHistory()
  const auth = useSelector(state => state.auth)
  const [t, i18n] = useTranslation("global")

  return (
    <div className={classes.introContainer}>
      <Grid
        container spacing={2}
        direction='row'
        justify='space-evenly'
      >
        <Grid item xs={12} md={7}>
          <div className={classes.titleLanding}>
            <h1>{t("title-landing.developers")}</h1>
            <h1>{t("title-landing.integrate")}</h1>
            <h4 className={classes.subtitle}> {t("title-landing.information")} </h4>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className={classes.imgPos}>
            <img alt='landing-img' className={classes.img} src={BG} />
          </div>
        </Grid>
      </Grid>
      <div className={`${classes.buttonLine} ${classes.infoButtonLine}`}>
        {!auth.loggingIn
          ? (
            <Button
              onClick={() => history.push('/sign-in')}
              label={t("title-landing.start-button")}
              type='secondary'
              size='big'
              data-testid='signin-btn'
            />)
          : (
            <Button
              onClick={() => history.push('/cases')}
              label={t("title-landing.user-manual-button")}
              type='secondary'
              size='big'
              data-testid='signin-btn'
            />)}
        <Button
          onClick={() => history.push('/docs')}
          label={t("title-landing.documentation-button")}
          type='primary'
          size='big'
          data-testid='signin-btn'
        />
      </div>
    </div>
  )
}
export default IntroContainer
