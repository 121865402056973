
export const logIn = (accessToken, role, email, id) => ({
  type: 'LOG_IN',
  payload: {
    accessToken: accessToken,
    role: role,
    email: email,
    id: id
  }
})

export const noLogging = () => ({
  type: 'NO_LOGGING',
  payload: null
})

export const logOut = () => ({
  type: 'LOG_OUT',
  payload: null
})
