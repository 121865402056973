import axios from 'axios'
import { URL } from '../url'

export default async function searchCategoryArticles (id, search, currentPage) {
  const data = {
    page: currentPage,
    search: search
  }
  const headers = {
    headers: {
    }
  }
  const response = await axios.post(URL + '/search/category/' + id.toString(), data, headers)

  return response
}
