import getStats from '../../utils/fetch/getStats'
import getStatsReady from '../../utils/fetch/getStatsReady'
import getLogs from '../../utils/fetch/getLogs'

const keyExample = [
  {
    key: 'xxxxxx1123',
    webhooks: [
      'https://3d860f5601c24fe0d0a6bda4c29ba82e.m.pipedream.net'
    ],
    account_id: 12
  }
]

const getTotalLogs = async (token, timeI, timeF, data, product) => {
  var urls = []
  var logsPromises = []
  // Key tiene el formato de keyExample (mirar arriba)
  // entonces para cada objeto vamos a revisar su info
  for (const key of data) {
    // Aquí guardamos los webhooks de cada uno para después
    // poder acceder a ellos
    urls = urls.concat(key.webhooks)
    for (const url of key.webhooks) {
      if (timeF - timeI > 15 && product === 'LM') {
        // Setiamos a traer solo los ultimos 15 minutos de logs
        timeI = timeF - 15
      }
      // Hacemos un array de promesas. Estas promesas son request
      // al backend por cada webhook. De esta manera podemos hacerlo asincrono
      logsPromises.push(getLogs(token, timeI, timeF, url, product, key.account_id))
    }
  }
  const totalLogs = {}
  // Esperamos que lleguen todas las promesas
  await Promise.all(logsPromises).then((values) => {
    for (const key in values) {
      // Accedemos a la lista que teniamos de urls y vamos uno a uno
      // asigandole el valor
      totalLogs[setLargeWh(urls[key])] = values[key].data
    }
    return totalLogs
  })
  return totalLogs
}

const cleanInfoBase = prevList => {
  const result = Object.assign({}, ...prevList)
  var hitFinal = []
  for (const stamp of Object.keys(result)) {
    hitFinal.push({ name: stamp, value: 0 })
  }
  return hitFinal
}

const generateBase = (whs) => {
  // Esto se hace para generar las keys de las horas a cada webhook
  // Hace un modlde el cual se va a rellenar después con 
  // el procesamineto de datos
  // De esta manera el gráfico puede identificar donde 
  // poner los datos.
  // 12/25/22 = 4 hits
  var hitList = []
  var eventList = []
  var processStateList = []
  var resourceList = []
  var trucksList = []
  for (const wh of Object.keys(whs)) {
    hitList.push(whs[wh].time)
    eventList.push(whs[wh].stats.event)
    processStateList.push(whs[wh].stats.process_state)
    resourceList.push(whs[wh].stats.resource)
    trucksList.push(whs[wh].stats.trucks)
  }
  return {
    hitFinal: cleanInfoBase(hitList).sort(compare),
    eventFinal: cleanInfoBase(eventList).sort(compare),
    resourceFinal: cleanInfoBase(resourceList).sort(compare),
    trucksFinal: cleanInfoBase(trucksList).sort(compare),
    processStateFinal: cleanInfoBase(processStateList).sort(compare)
  }
}
function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
const getTotalStats = async (token, timeF, timeI, data, product) => {
  // Parecido al método de logs, pero este genera timeF/15 workers por 
  // Webhook a consultat
  // Dado que usamos workers hay un pooling
  var urls = []
  const totalKeys = []
  for (const key of data) {
    urls = urls.concat(key.webhooks)
    for (const url of key.webhooks) {
      // geenramos todo los workers
      const keyUrl = await getStats(token, timeF, timeI, url, product, key.account_id)
      // registramos las keys
      totalKeys.push(keyUrl.data.key)
    }
  }
  const totalStatsP = {}
  var count = 0
  var whFormatP = { hits: {}, event: {}, process_state: {}, trucks: {}, resource: {} }
  // hasta que no lleguen los resultados
  // de todos los workers, seguimos haciendo pooling
  while (count < totalKeys.length) {
    // Preguntamos si esta listo
    const request = await getStatsReady(token, totalKeys[count])
    // Podría ser mejor
    if (request.data) {
      var list = []
      // Asignamos los datos qu rescatamos
      // vamos asignando valores segun tiempo para 
      // rellenar la list para de los gráficos
      totalStatsP[urls[count]] = request.data
      for (const stamp of Object.keys(request.data.time)) {
        list.push({ name: stamp, value: request.data.time[stamp] })
      }
      for (const stamp of Object.keys(request.data.stats)) {
        var listStats = []
        for (const stat of Object.keys(request.data.stats[stamp])) {
          listStats.push({ name: stat, value: request.data.stats[stamp][stat] })
        }
        whFormatP[stamp][setLargeWh(urls[count])] = listStats.sort(compare)
      }
      whFormatP.hits[setLargeWh(urls[count])] = list.sort(compare)
      count++
    } else {
      await sleep(1200)
    }
  }
  return { totalStatsP, whFormatP }
}

function compareDate (a, b) {
  if (a.timestamp > b.timestamp) {
    return -1
  }
  if (a.timestamp < b.timestamp) {
    return 1
  }
  return 0
}
function createList (totalData, whs, whSelected, sum) {
  var finalList = []
  if (sum) {
    for (const wh of whs) {
      finalList = finalList.concat(totalData[wh].hits)
    }
    finalList = finalList.concat(totalData[whSelected].hits)
  } else {
    for (const wh of whs) {
      if (wh !== whSelected) {
        finalList = finalList.concat(totalData[wh].hits)
      }
    }
  }
  return finalList.sort(compareDate)
}
function sumObjectsByKey (a, b, sum) {
  const max = b.length
  var i = 0
  var j = 0
  while (j < max) {
    if (a[i].name === b[j].name) {
      if (sum) {
        a[i] = { name: a[i].name, value: a[i].value + b[j].value }
      } else {
        a[i] = { name: a[i].name, value: a[i].value - b[j].value }
      }
      i++
      j++
    } else {
      i++
    }
  }
  return a
}

function compare (a, b) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

const setLargeWh = (webhook) => {
  const MAX = 40
  const length = webhook.length
  if (length >= MAX) return '...' + webhook.substr(length - MAX)
  return webhook
}
const webhooks = [
  'https://soa.lidermovil.cl/services/rtnBeetrack',
  'http://200.29.32.215/ServicioRestDGA/BeetrackParis2/',
  'https://fctopjbql9.execute-api.us-west-2.amazonaws.com/master/spread_to_falabella/integrate'
]

export {
  getTotalStats,
  webhooks,
  sumObjectsByKey,
  compare,
  setLargeWh,
  createList,
  generateBase,
  getTotalLogs
}
