import axios from 'axios'
import { URL } from '../url'

export default async function getStatsReady (token, key) {
  const body = {
    key: key
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.post(URL + '/dashboard/webhook/url/info', body, headers)
  return response
}
