import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  button: {
    textTransform: 'lowercase',
    fontSize: '0.9em'
  },
  selectedWh: {
    backgroundColor: '#2D2926',
    color: 'white'
  }
})

export default function ButtonWh (props) {
  const {
    handleSelectWebhook,
    webhook,
    selectWebhooks
  } = props
  const classes = useStyles()
  const [selected, setSelected] = useState(false)
  const selectWh = (webhook) => {
    handleSelectWebhook(webhook)
    setSelected(!selected)
  }
  useEffect(() => {
    if (selectWebhooks.includes(webhook)) return setSelected(true)
  },[selectWebhooks, webhook])
  return (
    <Button
      className={`${selected ? classes.selectedWh : classes.nonSelectedWh} ${classes.button}`}
      onClick={() => selectWh(webhook)}
    >
      {webhook}
    </Button>
  )
}