import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { logIn } from '../../redux/actions/authActions'
import { Grid } from '@material-ui/core'
import authentication from '../../utils/fetch/auth'
import { Button, TextField } from '../../components'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2%',
    alignItems: 'center'
  },
  title: {
    paddingTop: '1px',
    fontSize: '1.9em',
    color: '#3b4754'
  },
  card: {
    margin: 'auto',
    maxWidth: '40%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%'
    },
    backgroundColor: '#FFF',
    borderRadius: '20px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  },
  text: {
    paddingTop: '20px'
  },
  warningText: {
    color: 'red'
  },
  container: {
    padding: '8%'
  },
  borderLeft: {
    borderLeft: '1px solid #D3D3D3'
  }
}))

const SignIn = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { history } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = async (e) => {
    setLoading(true)
    const response = await authentication(email, password)
    if (response.data.id) {
      dispatch(logIn(response.headers.authorization, response.data.admin, response.data.email, response.data.id))
      history.push('/')
    } else {
      setError(true)
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.title}>
          <h3>Iniciar Sesión</h3>
        </div>
        <Grid container spacing={0} margin='0px'>
          <Grid item xs={12} lg={12}>
            <div className={classes.container}>
              <form onSubmit={() => { handleSubmit() }}>
                {error && <p className={classes.warningText}>Usuario y/o contraseña inválida</p>}
                <div className={classes.text}>
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                    label='Mail'
                    value={email}
                  />
                </div>
                <div className={classes.text}>
                  <TextField
                    type='password'
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Contraseña'
                    label='Contraseña'
                    value={password}
                  />
                </div>
                <div className={classes.text}>
                  {!loading && <Button size='signin' label='Ingresar' type='secondary' onClick={() => { handleSubmit() }} />}
                  {loading && <Button size='signin' label='Ingresar' type='disabled' />}
                </div>
                <div className={classes.text}>
                  <p>No tienes cuenta? <a href='/sign-up'>Regístrate!</a></p>
                  <p>Olvidaste tu contraseña? <a href='/forgot_password'>Recuperar Contraseña</a></p>
                </div>
              </form>
            </div>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <div className={`${classes.container} ${classes.borderLeft}`}>
              <div className={classes.text}>
                <Button size='signin' label='Ingresar con Google' type='google' /> 
              </div>
              <div className={classes.text}>
                <Button size='signin' label='Ingresar con Facebook' type='facebook' />
              </div>
              <div className={classes.text}>
                <Button size='signin' label='Ingresar con Github' type='primary' />
              </div>
            </div>
          </Grid> */}
        </Grid>
        
      </div>
    </div>
  )
}

export default SignIn
