import axios from 'axios'
import { URL } from '../url'

export default async function deleteLikeArticle (token, articleId) {
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.delete(URL + '/articles/like/' + articleId.toString(), headers)
  return response
}
