import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '../../../../components'
import Bee from '../../../../assets/images/beetrackimg.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  searchBar: {
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    alignItems: 'center',
    margin: 'auto',
    borderRadius: '5px'
  },
  card: {
    margin: 'auto',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  }
}))

const Landing = (props) => {
  const classes = useStyles()
  const { handleInputChange, searchText } = props
  const [t, i18n] = useTranslation("global")

  return (
    <div className={classes.searchBar}>
      <img alt='abejas' src={Bee} />
      <div className={classes.card}>
        <TextField
          label={t("faq.label")}
          placeholder={t("faq.placeholder")}
          onChange={handleInputChange}
          value={searchText}
          // trailingIcon={<Search />}
        />
      </div>
    </div>
  )
}
export default Landing
