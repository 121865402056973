import axios from 'axios'
import { URL } from '../url'

export default async function deleteComment (token, id) {
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.delete(URL + '/comments/' + id.toString(), headers)
  return response
}
