import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  
}))

const NotAccess = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      Not aacc
    </div>
  )
}

export default NotAccess
