import React from 'react'
import { Grid } from '@material-ui/core'
import { Button } from '../../../../components'
import FMSVG from '../../../../assets/images/H FM.svg'
import LMSVG from '../../../../assets/images/H LM.svg'
import PPSVG from '../../../../assets/images/H PP.svg'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ProductsContainer = (props) => {
  const [t, i18n] = useTranslation("global")
  const { classes } = props
  const history = useHistory()
  return (
    <div className={classes.subContainer}>
      <h2>{t("products-container.products")}</h2>
      <Grid
        container spacing={2}
        direction='row'
        justify='space-evenly'
        className={classes.productGrid}
      >
        <Grid item xs={12} lg={6} md={6}>
          <div className={classes.product}>
            <div className={classes.logoCont}>
              <img alt='landing-img' className={classes.productLogo} src={LMSVG} />
            </div>
            <Button
              onClick={() => history.push('/services/lastmile')}
              label={t("products-container.more")}
              type='LM'
              size='product'
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <div className={classes.product}>
            <div className={classes.logoCont}>
              <img alt='landing-img' className={classes.productLogo} src={PPSVG} />
            </div>
            <Button
              onClick={() => history.push('/services/plannerpro')}
              label={t("products-container.more")}
              type='PP'
              size='product'
            />
          </div>
        </Grid>
        {/* <Grid item xs={12} lg={3} md={6}>
          <div className={classes.product}>
            <div className={classes.logoCont}>
              <img alt='landing-img' className={classes.productLogo} src={FMSVG} />
            </div>
            <Button
              onClick={() => history.push('/services/firstmile')}
              label='Ver más'
              type='FM'
              size='product'
            />
          </div>
        </Grid> */}
        {/* <Grid item xs={12} lg={3} md={6}>
          <div className={classes.product}>
            <div className={classes.logoCont}>
              <img alt='landing-img' className={classes.productLogo} src={ODSVG} />
            </div>
            <Button
              onClick={() => history.push('/services/ondemand')}
              label='Ver más'
              type='OD'
              size='product'
            />
          </div>
        </Grid> */}
      </Grid>
    </div>
  )
}
export default ProductsContainer
