import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Popover } from './components'
import { Button } from '../../../../components'
import ButtonFree from '@material-ui/core/Button'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LMSVG from '../../../../assets/images/H LM.svg'
import PPSVG from '../../../../assets/images/H PP.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    width: '100%',
    backgroundColor: 'White',
    maxHeight: '56px',
    borderRadius: 4
  },
  borderB: {
    border: '0.1px solid rgba(133, 133, 133,0.5)'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  date: {
    padding: '8px',
    minHeight: '56px',
    margin: 'auto'
  },
  buttonUpdate: {
    padding: '5%'
  },
  iconMenu: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  icon: {
    margin: 'auto'
  },
  popOver: {
    padding: '10px'
  },
  input: {
    height: '20px'
  },
  button: {
    fontFamily: '"Open Sans"'
  },
  productLogo: {
    width: '300px',
    height: '50px'
  }
}))

const timeAdjustString = (value) => {
  value = parseInt(value)
  if (value === 0 || value === null) return 'ahora'
  if (value === 60) return '1 H'
  if (value === 1440) return '1 D'
  const dias = Math.floor(value / 1440)
  const horas = Math.floor((value - dias * 1440) / 60)
  const minutos = Math.floor((value - dias * 1440 - horas * 60))
  const d = dias ? dias.toString() + ' D ' : ''
  const h = horas ? horas.toString() + ' H ' : ''
  const m = minutos ? minutos.toString() + ' M' : ''
  return (d + h + m)
}

const HeaderPanel = (props) => {
  const classes = useStyles()
  const {
    setTimeF,
    setTimeI,
    timeF,
    timeI,
    product,
    refreshData,
    webhooks
  } = props
  // const [searchText, setSearchText] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [valueF, setValueF] = useState(timeF)
  const [valueI, setValueI] = useState(timeI)

  const handleUpdate = () => {
    if (timeF > timeI) {
      if (product === 'LM') {
        if ((timeF - timeI) <= 45) {
          refreshData(webhooks)
        } else {
          alert('Por ahora se soportan búsquedas de rango máximo 45 minutos')
        }
      }
      if (product === 'PP') {
        if ((timeF - timeI) <= 2880) {
          refreshData(webhooks)
        } else {
          alert('Por ahora se soportan búsquedas de rango máximo 48 horas')
        }
      }
    } else {
      alert('El tiempo final debe ser menor al inicial')
    }
  }

  // const handleInputChange = e => {
  //   setSearchText(e.target.value)
  // }
  const handleInputChangeTF = e => {
    setValueF(e.target.value)
  }
  const handleInputChangeTI = e => {
    setValueI(e.target.value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget)
  }
  const handleClose2 = () => {
    setAnchorEl2(null)
  }

  return (
    <Grid container spacing={4} margin='0px'>
      <Grid item xs={12} lg={4}>
        <div className={classes.container}>
          {/* <TextField
            label='¿Que webhook buscas?'
            placeholder='Escríbelo aquí'
            onChange={handleInputChange}
            value={searchText}
          /> */}
          <div className={classes.logoCont}>
            <img alt='landing-img' className={classes.productLogo} src={product === 'LM' ? LMSVG : PPSVG} />
          </div>
        </div>
      </Grid>
      <Grid item xs={8} lg={6}>
        <div className={`${classes.container} ${classes.borderB}`}>
          <div className={classes.date}>
            <Grid
              container
            >
              <Grid item xs={2} className={classes.iconMenu}>
                {/* <ButtonFree> */}
                <DateRangeIcon className={classes.icon} />
                {/* </ButtonFree> */}
              </Grid>
              <Grid item xs={4}>

                <ButtonFree className={classes.button} onClick={handleClick}>
                  {'~ ' + timeAdjustString(timeF)}
                </ButtonFree>
                <Popover
                  handleInputChangeTI={handleInputChangeTF}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  timeF={timeF}
                  valueF={valueF}
                  setTimeF={setTimeF}
                  final
                />
              </Grid>
              <Grid item xs={1} className={classes.iconMenu}>
                <ArrowRightAltIcon className={classes.icon} />
              </Grid>
              <Grid item xs={4}>
                <ButtonFree className={classes.button} onClick={handleClick2}>
                  {timeAdjustString(timeI)}
                </ButtonFree>
                <Popover
                  handleInputChangeTI={handleInputChangeTI}
                  anchorEl={anchorEl2}
                  handleClose={handleClose2}
                  timeF={timeI}
                  valueF={valueI}
                  setTimeF={setTimeI}
                  final={false}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid item xs={4} lg={2}>
        <div className={classes.buttonUpdate}>
          <Button onClick={handleUpdate} label='Actualizar' type='primary' size='product' />
        </div>
      </Grid>
    </Grid>
  )
}
export default HeaderPanel
