import React from 'react'
import { DefaultEditor } from 'react-simple-wysiwyg'

const TextEditor = (props) => {
  const {html, setHtml} = props

  return (
    <DefaultEditor value={html} onChange={(e) => setHtml(e.target.value)} />
  )
}

export default TextEditor
