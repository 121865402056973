import React, { useState, useEffect } from 'react'
import { QuestionCard, Landing, NewFAQ } from './components'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import getFaqs from '../../utils/fetch/faqs'
import createFaq from '../../utils/fetch/createFaq'
import deleteFaq from '../../utils/fetch/deleteFaq'
import editFaq from '../../utils/fetch/editFaq'
import { Button, CircularProgress } from '../../components'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      margin: 'auto',
      paddingTop: '4%'
    }
  },
  item: {
    padding: '2%'
  }
}))

const searchFilter = (data, searchWord) => {
  var newList = []
  for (const faq of data) {
    var agregada = false
    if (faq.key.includes(searchWord)) {
      newList.push(faq)
      agregada = true
    }
    if (!agregada) {
      if (faq.answer.includes(searchWord)) {
        newList.push(faq)
      }
    }
  }
  return newList
}

const Faq = () => {
  const auth = useSelector(state => state.auth)
  const theme = useTheme()
  const [newTitleFAQ, setNewTitleFAQ] = useState('')
  const [newDescriptionFAQ, setNewDescriptionFAQ] = useState('')
  const [newFaq, setNewFaq] = useState(false)
  const classes = useStyles(theme)
  const [faqs, setFaqs] = useState([])
  const [faqsSearched, setFaqsSearched] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const Faqs = () => {
    getFaqs()
      .then((data) => {
        setLoading(false)
        setFaqs([])
        setFaqs(data.data)
        setFaqsSearched(data.data)
      })
  }
  useEffect(() => {
    Faqs()
  }, [])

  const handleInputChange = e => {
    setSearchText(e.target.value)
    const data = searchFilter(faqs, e.target.value)
    setFaqsSearched(data)
  }
  const handleSubmitNewFaq = async (e) => {
    setLoading(true)
    await createFaq(auth.currentToken, newTitleFAQ, newDescriptionFAQ)
    handleNewFaq()
    Faqs()
  }
  const handleNewFaq = e => {
    setNewFaq(!newFaq)
  }
  const handleDelete = async (id) => {
    setLoading(true)
    await deleteFaq(auth.currentToken, id)
    Faqs()
  }
  const handleEdit = async (id, newTitle, newDescription) => {
    // setLoading(true) memory leak por setiar estado en componentes no montados
    await editFaq(auth.currentToken, id, newTitle, newDescription)
    Faqs()
  }
  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <Landing handleInputChange={handleInputChange} searchText={searchText} />
      </div>
      {auth.currentUserRole &&
        <div>
          <div className={classes.item}>
            {!newFaq && <Button size='medium' type='primary' label='Agregar nuevo FAQ' onClick={handleNewFaq} />}
            {newFaq && <Button size='medium' type='primary' label='Cerrar' onClick={handleNewFaq} />}
          </div>
          <div className={classes.item}>
            {newFaq &&
              <NewFAQ
                newTitleFAQ={newTitleFAQ}
                newDescriptionFAQ={newDescriptionFAQ}
                handleTitle={setNewTitleFAQ}
                handleDescription={setNewDescriptionFAQ}
                handleSubmitNewFaq={handleSubmitNewFaq}
              />}
          </div>
        </div>}
      <div className={classes.item}>
        {loading && <CircularProgress />}
        {!loading &&
          faqsSearched.map((faq) =>
            <QuestionCard
              key={faq.id}
              cardId={faq.id}
              title={faq.key}
              description={faq.answer}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              admin={auth.currentUserRole}
            />
          )}
      </div>
    </div>
  )
}

export default Faq
