import axios from 'axios'
import { URL } from '../url'

export default async function searchArticles (search, currentPage) {
  const data = {
    page: currentPage,
    search: search
  }
  const headers = {
    headers: {
    }
  }
  const response = await axios.post(URL + '/search', data, headers)

  return response
}
