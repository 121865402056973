import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { amber } from '@material-ui/core/colors'
import editPassword from '../../utils/fetch/editPassword'
import disconnect from '../../utils/fetch/disconnect'
import { logOut } from '../../redux/actions/authActions.js'
import getKeys from '../../utils/fetch/keys'
import createKey from '../../utils/fetch/newKey'
import deleteKey from '../../utils/fetch/deleteKey'
import { Password, ApiKeys, Modal } from './components'
import handleLogout from '../../utils/connect/logout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1%'

  },
  captcha: {
    textAlign: 'center',
    display: 'inline-block'
  },
  text: {
    padding: '5%'
  },
  warningText: {
    color: 'red'
  },
  amber: {
    color: 'black',
    backgroundColor: amber[600],
    width: '5rem',
    height: '5rem',
    margin: 'auto'
  },
  container: {
    marginTop: '30px'
  },
  icon: {
    alignItems: 'center',
    display: 'flex'
  }
}))

const Profile = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const [values, setValues] = useState({
    email: auth.currentEmail,
    password_actual: '',
    password: '',
    password2: ''
  })
  const [errorText, setErrorText] = useState('')
  const [errorText2, setErrorText2] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [keys, setKeys] = useState([])
  const [openNew, setOpenNew] = useState(false)
  const [newKey, setNewkey] = useState('')
  const [newKeySecret, setNewkeySecret] = useState('')
  const [comment, setComment] = useState('')
  const [reload, setReload] = useState(0)
  const [captcha, setCaptcha] = useState(false)
  const dispatch = useDispatch()
  const { history } = props
  const [t, i18n] = useTranslation("global")
  const keysUpdate = async () => {
    const result = await getKeys(auth.currentToken)
    setKeys(result.data)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
    const validPassword = values.password === value
    const validPassword2 = values.password2 === value
    if ((validPassword && (name === 'password2') && values.password !== '') || (validPassword2 && (name === 'password') && values.password2 !== '')) {
      setError(false)
    } else {
      if (values.password !== '') {
        setError(true)
        setErrorText2(t("profile.password-error"))
      }
    }
  }

  const handleNewKey = async (type) => {
    if (!captcha) {
      setErrorText(t("profile.humanity"))
      setError(true)
      return
    }
    try {
      if (type === '2') {
        const key = newKeySecret ? newKey + ':' + newKeySecret : newKey
        await createKey(auth.currentToken, key, comment, 'pp')
      } else {
        await createKey(auth.currentToken, newKey, comment, 'lm')
      }
      keysUpdate()
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout(history)
      } else if (error.response.status === 404) {
        alert(t("profile.no-webhooks"))
      }
    }
    setNewkey('')
    setNewkeySecret('')
    setComment('')
    setOpenNew(false)
    setReload(reload + 1)
  }

  const handleDelete = async (id) => {
    try {
      await deleteKey(auth.currentToken, id)
      setReload(reload + 1)
      keysUpdate()
    } catch (error) {
      if (error.response.status === 401) {
        handleLogout(history)
      }
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    await editPassword(auth.currentToken, values.password_actual, values.password)
      .then(async (response) => {
        await disconnect(auth.currentToken)
        setError(false)
        dispatch(logOut())
        history.push('/sign-in')
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setError(true)
          setErrorText(t("profile.incorrect-password"))
          setLoading(false)
        } else {
          setError(true)
          setErrorText(t("profile.server-error"))
          setLoading(false)
        }
      })
  }
  function onChange (value) {
    setCaptcha(!captcha)
  }
  useEffect(() => {
    async function fetchData () {
      try {
        await keysUpdate()
      } catch (error) {
      }
    }
    fetchData()
  }, [])
  return (
    <div className={classes.root}>
      {auth.loggingIn && (
        <div>
          <Password
            classes={classes}
            values={values}
            handleInputChange={handleInputChange}
            loading={loading}
            error={error}
            handleSubmit={handleSubmit}
            errorText={errorText2}
          />
          <ApiKeys
            classes={classes}
            keys={keys}
            handleOpenNew={setOpenNew}
            handleDelete={handleDelete}
          />
          <Modal
            setNewkeySecret={setNewkeySecret}
            newKeySecret={newKeySecret}
            errorText={errorText}
            classes={classes}
            onChange={onChange}
            newKey={newKey}
            open={openNew}
            setOpen={setOpenNew}
            comment={comment}
            setComment={setComment}
            setNewkey={setNewkey}
            handleClickFunc={handleNewKey}
          />
        </div>

      )}
    </div>
  )
}

export default Profile
