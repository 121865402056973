import axios from 'axios'
import { URL } from '../url'

export default async function createService (token, name, nameEn, description, descriptionEn, appId) {
  const data = {
    name: name,
    name_en: nameEn,
    field: description,
    field_en: descriptionEn,
    app_id: appId
  }
  const headers = {
    headers: {
      Authorization: token // the token is a variable which holds the token
    }
  }
  const response = await axios.post(URL + '/services', data, headers)
  return response
}
